import { notify } from '@onsaui'
import { isAxiosError } from 'axios'
import { useState } from 'react'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { taskApi, TranscriptReportSignedUrlRequest } from '@/modules/task/api'
import { TaskType } from '@/modules/task/model'
import AnalyticsService from '@/services/analytics'

const useCreateTranscriptReportTask = (onLimitExeededError?: () => void) => {
  const [isCreating, setIsCreating] = useState(false)

  const createTask = async (file: File, context?: string, isAnon?: boolean) => {
    AnalyticsService.trackEvent('CreateTask.Start', { taskType: TaskType.TranscriptReport })

    setIsCreating(true)
    try {
      const requestDto: TranscriptReportSignedUrlRequest = {
        fileName: file.name,
        mimeType: file.type,
        fileSize: file.size,
        taskType: TaskType.TranscriptReport,
        context,
      }
      const { signedUrl, taskId } = isAnon
        ? await taskApi.createAnonSignedUrl(requestDto)
        : await taskApi.createSignedUrl(requestDto)

      await taskApi.uploadFileToBucket(file, signedUrl)

      const task = isAnon
        ? await taskApi.createAnonTranscriptReport(taskId)
        : await taskApi.createTranscriptReport(taskId)

      AnalyticsService.trackEvent('CreateTask.Success', {
        taskType: task.type,
        taskId: task.id,
      })

      return task
    } catch (e) {
      let isHandled = false
      if (isAxiosError(e)) {
        if (e.response?.status === 402) {
          isHandled = true
          AnalyticsService.trackEvent('CreateTask.ErrorLimitExeeded', {
            taskType: TaskType.TranscriptReport,
          })
          notify({ message: getErrorMessage(e), variant: 'error' })
          onLimitExeededError?.()
        }
        if (e.response?.status === 429) {
          isHandled = true
          notify({ message: getErrorMessage(e), variant: 'error' })
        }
      }

      if (!isHandled) {
        AnalyticsService.trackEvent('CreateTask.Error', {
          taskType: TaskType.TranscriptReport,
          message: getErrorMessage(e),
        })

        notify({ message: getErrorMessage(e), variant: 'error' })
      }

      throw e
    } finally {
      setIsCreating(false)
    }
  }

  return { createTask, isCreating }
}

export default useCreateTranscriptReportTask
