import { Loader, useHighlightAnim } from '@onsaui'
import cx from 'clsx'
import React, { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import GeneralLayout from '@/modules/general/components/GeneralLayout/GeneralLayout'
import WorkspaceHeader from '@/modules/general/components/WorkspaceHeader/WorkspaceHeader'
import WorkspaceSidebar from '@/modules/general/components/WorkspaceSidebar/WorkspaceSidebar'
import CreateIcpWidget from '@/modules/icp/components/CreateIcpWidget/CreateIcpWidget'
import { Icp } from '@/modules/icp/model'
import CreatePersonMemoTaskWidget from '@/modules/task/components/CreatePersonMemoTaskWidget/CreatePersonMemoTaskWidget'
import CreateTranscriptReportTaskWidget from '@/modules/task/components/CreateTranscriptReportTaskWidget/CreateTranscriptReportTaskWidget'
import { AnyTask, TaskType } from '@/modules/task/model'
import useWorkspace from '@/modules/workspace/hooks/useWorkspace'
import GlobalEventService from '@/services/globalEvent'

const WorkspacePage: React.FC = () => {
  const navigate = useNavigate()
  const { workspaceId } = useParams()

  const { data: workspace, isPending, isError } = useWorkspace(workspaceId!)
  const transcriptReportTaskConfig = workspace?.availableTasks.find(
    (task) => task.type === TaskType.TranscriptReport,
  )
  const personMemoTaskConfig = workspace?.availableTasks.find(
    (task) => task.type === TaskType.PersonMemo,
  )

  const handleTaskCreated = (task: AnyTask) => {
    navigate(`/${workspaceId}/t/${task.id}`)
  }

  const handleIcpCreated = (icp: Icp) => {
    navigate(`/${workspaceId}/icp/${icp.id}`)
  }

  const [highlightClass, highlightIt] = useHighlightAnim()
  const handleAddNewTaskClick = useCallback(() => {
    highlightIt()
  }, [highlightIt])

  useEffect(() => {
    GlobalEventService.main.on('addNewTaskClick', handleAddNewTaskClick)
    return () => {
      GlobalEventService.main.off('addNewTaskClick', handleAddNewTaskClick)
    }
  }, [handleAddNewTaskClick])

  let body = null
  if (isPending) {
    body = (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <Loader />
      </div>
    )
  } else if (isError) {
    body = (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <p className="text-danger">Unable to load workspace...</p>
      </div>
    )
  } else {
    body = (
      <div className="my-auto flex flex-row flex-wrap justify-center gap-12 px-6 pb-8">
        {personMemoTaskConfig ? (
          <CreatePersonMemoTaskWidget
            className={cx(highlightClass)}
            onTaskCreated={handleTaskCreated}
            creditsCost={personMemoTaskConfig.creditsCost}
          />
        ) : null}
        {transcriptReportTaskConfig ? (
          <CreateTranscriptReportTaskWidget
            className={cx(highlightClass)}
            onTaskCreated={handleTaskCreated}
            creditsCost={transcriptReportTaskConfig.creditsCost}
          />
        ) : null}

        <CreateIcpWidget className={cx(highlightClass)} onIcpCreated={handleIcpCreated} />
      </div>
    )
  }

  return <GeneralLayout sidebar={<WorkspaceSidebar />} header={<WorkspaceHeader />} body={body} />
}

export default WorkspacePage
