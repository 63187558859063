import cx from 'clsx'

const TierBadge: React.FC<{ type: 'free' | 'pro'; className?: string }> = ({ type, className }) => {
  if (type === 'free') {
    return (
      <div
        className={cx(
          'flex flex-row items-center rounded-full bg-disabled px-2 py-1 text-white',
          className,
        )}
      >
        <span className="text-xs">Free</span>
      </div>
    )
  }
  if (type === 'pro') {
    return (
      <div
        className={cx(
          'flex flex-row items-center rounded-full bg-primary px-2 py-1 text-white',
          className,
        )}
      >
        <span className="text-xs">PRO</span>
      </div>
    )
  }
}

export default TierBadge
