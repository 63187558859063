import { Loader } from '@onsaui'
import { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import useAuth from '@/modules/auth/hooks/useAuth'
import useWorkspaces from '@/modules/workspace/hooks/useWorkspaces'

const NavigateToWorkspace: React.FC<{ path?: string }> = ({ path = '' }) => {
  const navigate = useNavigate()
  const { isAuth } = useAuth()

  const { data: workspaces } = useWorkspaces()
  useEffect(() => {
    if (!workspaces) {
      return
    }
    const initialWorkspace = workspaces[0]
    navigate(`/${initialWorkspace.id}${path}`)
  }, [workspaces, navigate, path])

  if (!isAuth) {
    return <Navigate to="/playground" replace />
  }

  return (
    <main className="bg-level-1 flex h-full w-full flex-col items-center justify-center p-4">
      <Loader />
    </main>
  )
}

export default NavigateToWorkspace
