import { useQuery } from '@tanstack/react-query'

import { icpApi } from '@/modules/icp/api'

const useIcps = () => {
  return useQuery({
    queryKey: ['icps'],
    queryFn: () => {
      return icpApi.getIcps()
    },
  })
}

export default useIcps
