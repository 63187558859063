import { create } from 'zustand'

export interface BillingStore {
  isPlanSelectModalOpen: boolean
  isUpgradeToProModalOpen: boolean
  isUpgradeToProPromoModalOpen: boolean
  isContactSalesModalOpen: boolean
}

export const useBillingStore = create<BillingStore>((set) => ({
  isPlanSelectModalOpen: false,
  isUpgradeToProModalOpen: false,
  isUpgradeToProPromoModalOpen: false,
  isContactSalesModalOpen: false,
}))

export const openPlanSelectModal = () => {
  useBillingStore.setState({ isPlanSelectModalOpen: true })
}
export const closePlanSelectModal = () => {
  useBillingStore.setState({ isPlanSelectModalOpen: false })
}

export const openUpgradeToProPromoModal = () => {
  useBillingStore.setState({ isUpgradeToProPromoModalOpen: true })
}
export const closeUpgradeToProPromoModal = () => {
  useBillingStore.setState({ isUpgradeToProPromoModalOpen: false })
}

export const openContactSalesModal = () => {
  useBillingStore.setState({ isContactSalesModalOpen: true })
}
export const closeContactSalesModal = () => {
  useBillingStore.setState({ isContactSalesModalOpen: false })
}

export const openUpgradeToProModal = () => {
  useBillingStore.setState({ isUpgradeToProModalOpen: true })
}
export const closeUpgradeToProModal = () => {
  useBillingStore.setState({ isUpgradeToProModalOpen: false })
}

// @ts-expect-error - expose to the window
window.openPlanSelectModal = openPlanSelectModal
// @ts-expect-error - expose to the window
window.openUpgradeToProPromoModal = openUpgradeToProPromoModal
// @ts-expect-error - expose to the window
window.openUpgradeToProModal = openUpgradeToProModal
