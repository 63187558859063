import { Button, Input } from '@onsaui'
import { IllustrationPerson } from '@onsaui/icons'
import cx from 'clsx'
import { useState } from 'react'

import { openSignInToContinueModal } from '@/modules/auth'
import PriceBadge from '@/modules/billing/components/PriceBadge/PriceBadge'
import { openUpgradeToProPromoModal } from '@/modules/billing/store/billingStore'
import useCreatePersonMemoTask from '@/modules/task/hooks/useCreatePersonMemoTask'
import useDemoTask from '@/modules/task/hooks/useDemoTask'
import { PersonMemo, TaskType } from '@/modules/task/model'
import AnalyticsService from '@/services/analytics'

import CreateTaskContextField from '../CreateTaskContextField/CreateTaskContextField'

const CreatePersonMemoTaskWidget: React.FC<{
  onTaskCreated?: (task: PersonMemo.Task) => void
  className?: string
  isAnon?: boolean
  creditsCost?: number
}> = ({ onTaskCreated, className, isAnon, creditsCost }) => {
  const [personUrl, setPersonUrl] = useState('')
  const handlePersonUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonUrl(e.target.value)
  }

  const [context, setContext] = useState('')
  const handleContextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContext(e.target.value)
  }

  const handleLimitExeededError = () => {
    if (isAnon) {
      openSignInToContinueModal()
    } else {
      openUpgradeToProPromoModal()
    }
  }

  const { isCreating, createTask } = useCreatePersonMemoTask(handleLimitExeededError)

  const handleCreate = async () => {
    AnalyticsService.trackEvent('CreatePersonMemoTaskWidget.BtnCreate.Click')

    const contextTrimmed = context.trim().length ? context : undefined
    const task = await createTask(personUrl, contextTrimmed, isAnon)
    onTaskCreated?.(task)
  }

  const { openDemoTask, isDemoTaskLoading } = useDemoTask()

  return (
    <section
      className={cx(
        'relative flex max-w-md flex-col items-center rounded-[40px] bg-level1 px-6 pb-6 pt-16',
        className,
      )}
    >
      {creditsCost && (
        <PriceBadge price={creditsCost} className="absolute -right-3 top-2 -translate-y-1/2" />
      )}

      <IllustrationPerson className="mb-5" />
      <h2 className="mb-2 text-center">Create a Person Memo</h2>
      <p className="mb-5 text-center">
        Send me the LinkedIn profile of the person you want to prepare for a call with.
      </p>

      <div className="flex-grow" />

      <div className="flex w-full flex-col">
        <Input
          id="personUrl"
          className="mb-4 w-full"
          placeholder="Enter person linkedin profile url"
          value={personUrl}
          onChange={handlePersonUrlChange}
        />

        <CreateTaskContextField className="mb-4" value={context} onChange={handleContextChange} />

        <Button
          className="w-full"
          isDisabled={!personUrl.trim().length || isCreating}
          isLoading={isCreating}
          onClick={handleCreate}
        >
          Send
        </Button>
      </div>

      <Button
        className="mt-1"
        variant="ghost"
        // eslint-disable-next-line react/jsx-no-bind
        onClick={openDemoTask.bind(null, TaskType.PersonMemo)}
        isDisabled={isDemoTaskLoading}
      >
        Try Demo Profile for Free
      </Button>
    </section>
  )
}

export default CreatePersonMemoTaskWidget
