import { useEffect, useRef } from 'react'

import { icpActions } from '@/modules/icp'

import useIcp from './useIcp'

const useIcpPoling = (icpId: string) => {
  const { data: icp } = useIcp(icpId)

  const icpStatusRef = useRef(icp?.status)

  useEffect(() => {
    if (!icp) {
      return
    }

    if (
      icpStatusRef.current &&
      icpStatusRef.current !== 'completed' &&
      icp.status === 'completed'
    ) {
      icpActions.invalidateIcps()
    }
    icpStatusRef.current = icp.status

    if (icp.status !== 'completed' && icp.status !== 'failed') {
      const interval = setInterval(() => {
        icpActions.invalidateIcp(icpId)
      }, 2000)
      return () => clearInterval(interval)
    }
  }, [icp, icpId])
}
export default useIcpPoling
