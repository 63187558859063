import { useQuery } from '@tanstack/react-query'

import { workspaceApi } from '@/modules/workspace/api'

const useWorkspace = (workspaceId: string) => {
  return useQuery({
    queryKey: ['workspace', workspaceId],
    queryFn: () => {
      return workspaceApi.getWorkspace(workspaceId)
    },
  })
}

export default useWorkspace
