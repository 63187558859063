import cx from 'clsx'

const PriceBadge: React.FC<{ price: number; className?: string }> = ({ price, className }) => {
  return (
    <div className={cx('rounded-full bg-black px-3 py-2 text-sm text-white', className)}>
      <span className="font-bold">{price}</span> credits
    </div>
  )
}
export default PriceBadge
