import { Button } from '@onsaui'
import { IconPlusSm, IllustrationWarning } from '@onsaui/icons'
import cx from 'clsx'
import { useNavigate, useParams } from 'react-router-dom'

import useAuth from '@/modules/auth/hooks/useAuth'
import useDeleteTask from '@/modules/task/hooks/useDeleteTask'
import { AnyTask } from '@/modules/task/model'

const TaskErrorState: React.FC<{ task: AnyTask; className?: string }> = ({ task, className }) => {
  const { workspaceId } = useParams()

  const { isAuth } = useAuth()

  const navigate = useNavigate()

  const { deleteTask, isDeleting } = useDeleteTask()
  const handleDeleteTask = () => {
    deleteTask(task.id)
  }

  const handleTryAgain = () => {
    if (workspaceId) {
      navigate(`/${workspaceId}`)
    } else {
      navigate('/')
    }
  }

  const errorMessage =
    (task.responseData as { errorMessage?: string })?.errorMessage || 'Unknown error'

  return (
    <div className={cx('flex max-w-[380px] flex-col items-center text-center', className)}>
      <IllustrationWarning className="mb-6 text-danger" />

      <h2 className="mb-2 font-semibold">Failed to Generate Report</h2>
      <p>{errorMessage}</p>

      <div className="mt-6 flex w-full flex-row gap-2">
        {isAuth ? (
          <Button
            className="w-full"
            variant="outlined"
            onClick={handleDeleteTask}
            isDisabled={isDeleting}
            isLoading={isDeleting}
          >
            Delete this task
          </Button>
        ) : null}
        <Button className="w-full" variant="primary" onClick={handleTryAgain}>
          Try Again <IconPlusSm className="ml-2" />
        </Button>
      </div>
    </div>
  )
}

export default TaskErrorState
