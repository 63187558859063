import { Button, Input, notify } from '@onsaui'
import { IconGoogle, IllustrationWelcome } from '@onsaui/icons'
import dayjs from 'dayjs'
import React, { FormEvent, useState } from 'react'

import { authActions } from '@/modules/auth'
import { authApi } from '@/modules/auth/api'
import AnalyticsService from '@/services/analytics'
import { SUPPORT_EMAIL } from '@/shared/constants/supportEmail'
import isValidEmail from '@/shared/helpers/isValidEmail'

const EmailStep: React.FC<{
  redirect?: string
  onEmailSent: (email: string) => void
}> = ({ redirect, onEmailSent }) => {
  const [email, setEmail] = useState('')
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim())
  }

  const [isLoading, setIsLoading] = useState(false)

  const sendEmail = async (email: string) => {
    try {
      setIsLoading(true)
      await authApi.sendAuthEmail(email, dayjs.tz.guess(), redirect)

      setIsLoading(false)

      onEmailSent(email)
    } catch (e) {
      console.log('send email', e)
      // ErrorService.captureException(e);
      // AnalyticsService.sendEvent("signin_error", {
      //   errorMessage: getErrorMessage(e),
      // });
      // notify(getErrorMessage(e), "error");
    } finally {
      setIsLoading(false)
    }
  }

  const handleSignIn = (e: FormEvent) => {
    e.preventDefault()

    if (!isValidEmail(email)) {
      notify({ message: 'Please enter a valid email address.', variant: 'error' })
      return
    }

    AnalyticsService.trackEvent('SignInForm.BtnContinue.Click')

    sendEmail(email)
  }

  const handleAuthViaGoogle = async () => {
    AnalyticsService.trackEvent('SignInForm.BtnContinueWithGoogle.Click')
  }

  return (
    <form className="flex flex-col gap-5 text-center" onSubmit={handleSignIn}>
      <IllustrationWelcome className="self-center" />

      <h2 className="mb-2">Welcome to Onsa.ai</h2>

      <Button
        variant="outlined"
        type="button"
        className="w-full gap-2"
        onClick={handleAuthViaGoogle}
        isDisabled={isLoading}
        asChild
      >
        <a href={authActions.getGoogleAuthUrl()} target="_self">
          <IconGoogle className="shrink-0" />
          <span>Sign In with Google</span>
        </a>
      </Button>

      <p className="">Or</p>

      <div className="flex flex-col">
        <Input
          id="email"
          name="email"
          autoCapitalize="off"
          autoCorrect="off"
          className=""
          placeholder="adele@gmail.com"
          value={email}
          onChange={handleInputChange}
          isDisabled={isLoading}
        />

        <Button type="submit" className="mt-2 w-full" isDisabled={isLoading} isLoading={isLoading}>
          Sign In with Email
        </Button>

        <Button variant="ghost" className="mt-3" asChild>
          <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noreferrer">
            Contact support
          </a>
        </Button>
      </div>
    </form>
  )
}

export default EmailStep
