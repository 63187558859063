import { create } from 'zustand'

export interface AuthStore {
  token: string | null
  userId: string | null
  sessionId: string | null

  isSignInModalOpen: boolean
  singInModalRedirect?: string

  isSignInToContinueModalOpen: boolean
}

export const useAuthStore = create<AuthStore>((set) => ({
  token: null,
  userId: null,
  sessionId: null,

  isSignInModalOpen: false,
  singInModalRedirect: undefined,

  isSignInToContinueModalOpen: false,
}))

export const openSignInModal = (redirect?: string) => {
  useAuthStore.setState({ isSignInModalOpen: true, singInModalRedirect: redirect })
}
export const closeSignInModal = () => {
  useAuthStore.setState({ isSignInModalOpen: false, singInModalRedirect: undefined })
}

export const openSignInToContinueModal = () => {
  useAuthStore.setState({ isSignInToContinueModalOpen: true })
}
export const closeSignInToContinueModal = () => {
  useAuthStore.setState({ isSignInToContinueModalOpen: false })
}
