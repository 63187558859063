import { Button } from '@onsaui'
import { IllustrationWelcome } from '@onsaui/icons'
import cx from 'clsx'

import { openSignInModal } from '@/modules/auth/store/authStore'
import AnalyticsService from '@/services/analytics'

const AuthSuggest: React.FC<{ className?: string; from?: string }> = ({ className, from }) => {
  const handleShowSignInModal = () => {
    AnalyticsService.trackEvent('BtnSignIn.Click', { from })

    openSignInModal()
  }
  return (
    <div className={cx('flex flex-col gap-5 text-center', className)}>
      <IllustrationWelcome className="self-center" />

      <h2 className="">Welcome to Onsa.ai</h2>
      <p>Sign In or Sign Up to save reports history and get more relevant evaluation</p>

      <div className="">
        <Button className="!flex w-full" onClick={handleShowSignInModal}>
          Sign In
        </Button>
        {/* <Button className="mt-2 !flex w-full" variant="outlined">
          Sign In
        </Button> */}
      </div>
    </div>
  )
}

export default AuthSuggest
