import { Modal } from '@onsaui'

import { closeTaskSharingModal, useTaskStore } from '@/modules/task/store/taskStore'

import TaskSharingBody from './TaskSharingBody'

const TaskSharingModal: React.FC = () => {
  const { taskSharingModal } = useTaskStore()
  const { isOpen, taskId } = taskSharingModal

  const handleClose = () => {
    closeTaskSharingModal()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="flex w-full max-w-md flex-col gap-6">
      {taskId ? <TaskSharingBody taskId={taskId} /> : null}
    </Modal>
  )
}

export default TaskSharingModal
