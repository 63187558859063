import { Button } from '@onsaui'
import { IconPlusSm } from '@onsaui/icons'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AuthSuggest from '@/modules/auth/components/AuthSuggest/AuthSuggest'
import SignInOrLostProgressModal from '@/modules/auth/components/SignInOrLostProgressModal/SignInOrLostProgressModal'

const AnonSidebar: React.FC = () => {
  const { taskId } = useParams()
  const isTaskPage = Boolean(taskId)

  const [isLostProgressModalOpen, setIsLostProgressModalOpen] = useState(false)

  const navigate = useNavigate()
  const handleTryAgainProceed = () => {
    navigate('/', { replace: false })
  }

  if (isTaskPage) {
    return (
      <div className="flex flex-grow flex-col px-6">
        <SignInOrLostProgressModal
          isOpen={isLostProgressModalOpen}
          onClose={() => setIsLostProgressModalOpen(false)}
          onProceed={handleTryAgainProceed}
        />

        <Button variant="outlined" onClick={() => setIsLostProgressModalOpen(true)}>
          <span>Add New</span>
          <IconPlusSm className="ml-2 inline-block" />
        </Button>

        <div className="flex-grow" />

        <AuthSuggest className="pb-10" from="PagePlaygroundTask" />
      </div>
    )
  }

  return (
    <div className="flex flex-grow flex-col px-6">
      <AuthSuggest className="mt-[30%]" from="PagePlayground" />
    </div>
  )
}
export default AnonSidebar
