import { notify } from '@onsaui'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { taskActions } from '@/modules/task/actions'

const useDeleteTask = () => {
  const [isDeleting, setIsDeleting] = useState(false)

  const { workspaceId, taskId: paramTaskId } = useParams()
  const navigate = useNavigate()

  const deleteTask = async (taskId: string, onDeleted?: () => void) => {
    setIsDeleting(true)

    let isDeleted = false
    try {
      await taskActions.deleteTask(taskId)

      if (paramTaskId === taskId) {
        if (workspaceId) {
          navigate(`/${workspaceId}`)
        } else {
          navigate('/')
        }
      }

      isDeleted = true
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
    } finally {
      setIsDeleting(false)
    }

    if (isDeleted) {
      onDeleted?.()
    }
  }

  return { deleteTask, isDeleting }
}

export default useDeleteTask
