import { OnsaLogo } from '@onsaui'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import AnonSidebar from '@/modules/general/components/AnonSidebar/AnonSidebar'
import GeneralLayout from '@/modules/general/components/GeneralLayout/GeneralLayout'
import CreatePersonMemoTaskWidget from '@/modules/task/components/CreatePersonMemoTaskWidget/CreatePersonMemoTaskWidget'
import CreateTranscriptReportTaskWidget from '@/modules/task/components/CreateTranscriptReportTaskWidget/CreateTranscriptReportTaskWidget'
import { AnyTask } from '@/modules/task/model'

const PlaygroundPage: React.FC = () => {
  const navigate = useNavigate()
  const handleTaskCreated = (task: AnyTask) => {
    navigate(`/playground/t/${task.id}`)
  }

  return (
    <GeneralLayout
      sidebar={<AnonSidebar />}
      header={<OnsaLogo />}
      body={
        <div className="my-auto flex flex-row flex-wrap justify-center gap-12 px-6 pb-8">
          <CreatePersonMemoTaskWidget onTaskCreated={handleTaskCreated} isAnon />
          <CreateTranscriptReportTaskWidget onTaskCreated={handleTaskCreated} isAnon />
        </div>
      }
    />
  )
}

export default PlaygroundPage
