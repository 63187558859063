import { Loader } from '@onsaui'
import cx from 'clsx'

import useBillingPlans from '@/modules/billing/hooks/useBillingPlans'
import useUpgradeToPlan from '@/modules/billing/hooks/useUpgradeToPlan'
import { BillingPlan, BillingPlanTier } from '@/modules/billing/model'
import AnalyticsService from '@/services/analytics'

import BillingPlanItem from '../BillingPlanItem/BillingPlanItem'

const PlanPriority: Record<BillingPlanTier, number> = {
  [BillingPlanTier.FREE]: 1,
  [BillingPlanTier.PRO]: 2,
  [BillingPlanTier.CUSTOM]: 3,
}

const BillingPlanSelect: React.FC<{
  isAnnual?: boolean
  onPlanUpgraded?: () => void
  className?: string
}> = ({ isAnnual = false, onPlanUpgraded, className }) => {
  const { data: plans, isPending, isError, error } = useBillingPlans()

  const { processingPlanId, upgradeToPlan } = useUpgradeToPlan()

  const handlePlanSelected = async (plan: BillingPlan) => {
    AnalyticsService.trackEvent('BillingPlanSelect.BtnUpgradeToPlan.Click', {
      planTier: plan.tier,
      planId: plan.id,
    })

    await upgradeToPlan(plan, isAnnual, 1)

    if (plan.tier === BillingPlanTier.PRO) {
      onPlanUpgraded?.()
    }
  }

  if (isPending) {
    return (
      <div className={cx('flex min-h-[400px] flex-col items-center justify-center', className)}>
        <Loader />
      </div>
    )
  }

  if (isError) {
    return (
      <div className={cx('flex min-h-[400px] flex-col items-center justify-center', className)}>
        <p className="text-center text-danger">{error?.message || 'Something went wrong'}</p>
      </div>
    )
  }

  const sortedPlans = plans?.sort((a, b) => PlanPriority[a.tier] - PlanPriority[b.tier])

  return (
    <div className={cx('flex flex-row justify-center gap-8', className)}>
      {sortedPlans.map((plan) => (
        <BillingPlanItem
          key={plan.id}
          plan={plan}
          isCurrent={plan.isCurrent}
          isAnnual={isAnnual}
          onPlanSelected={handlePlanSelected}
          isLoading={processingPlanId === plan.id}
        />
      ))}
    </div>
  )
}
export default BillingPlanSelect
