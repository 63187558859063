import { TranscriptReportTaskData } from './transcriptReportInterfaces'

export const transformTranscriptReportToMarkdown = (data: TranscriptReportTaskData) => {
  let markdown = `# Call Analysis: ${data.analysis.call_title}\n\n`

  markdown += `## Summary\n\n${data.analysis.call_summary}\n\n`
  markdown += `- **Duration**: ${data.analysis.duration_mins} minutes\n`
  markdown += `- **Stage Detected**: ${data.analysis.detected_stage}\n`
  markdown += `- **Goal Achieved**: ${data.analysis.goal_achieved}\n`
  markdown += `- **Score**: ${data.analysis.total_score.actual}/${data.analysis.total_score.max}\n\n`

  markdown += `## Recommendations\n\n${data.analysis.advice}\n\n`

  if (data.analysis.criteria.length > 0) {
    markdown += `## Principles\n\n`
    data.analysis.criteria.forEach((criterion) => {
      markdown += `### ${criterion.title}\n\n`
      markdown += `- **Score**: ${criterion.score}\n`
      markdown += `- **Comment**: ${criterion.comment}\n\n`
    })
  }

  if (data.analysis.custom.length > 0) {
    markdown += `## Custom Comments\n\n`
    data.analysis.custom.forEach((customComment) => {
      markdown += `### ${customComment.title}\n\n`
      markdown += `${customComment.comment}\n\n`
    })
  }

  // Corrections
  if (data.corrections.length > 0) {
    markdown += `## Corrections\n\n`
    data.corrections.forEach((correction) => {
      markdown += `- **Timestamp**: ${correction.timestamp}\n`
      markdown += `  - **Original Phrase**: ${correction.original_phrase}\n`
      markdown += `  - **Suggestion**: ${correction.suggestion}\n`
      markdown += `  - **New Phrase**: ${correction.new_phrase}\n\n`
    })
  }

  return markdown
}
