import { AnyTask } from './taskInterfaces'
import * as PersonMemo from './tasks/personMemo'
import * as TrascriptReport from './tasks/transcriptReport'
import { TaskStatus, TaskType } from './taskTypeInterfaces'

export const getTaskTitle = (task: AnyTask) => {
  return task.title
}

export const transformTaskToMarkdown = (task: AnyTask) => {
  if (task.type === TaskType.TranscriptReport) {
    if (task.status !== TaskStatus.Completed) {
      throw new Error('Task must be completed to transform to markdown')
    }

    const data = task.responseData
    return TrascriptReport.helpers.transformTranscriptReportToMarkdown(data)
  }

  if (task.type === TaskType.PersonMemo) {
    if (task.status !== TaskStatus.Completed) {
      throw new Error('Task must be completed to transform to markdown')
    }

    const data = task.responseData
    return PersonMemo.helpers.transformPersonMemoToMarkdown(data)
  }

  throw new Error('Unknown task type to copy')
}
