import { Button } from '@onsaui'
import { IllustrationSpace } from '@onsaui/icons'
import { isAxiosError } from 'axios'
import cx from 'clsx'

import { openSignInModal } from '@/modules/auth'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import imgReportBlured from '@/modules/task/assets/report_blured.png'
import AnalyticsService from '@/services/analytics'

const TaskErrorWidget: React.FC<{ error: unknown; authRedirect?: string; className?: string }> = ({
  error,
  authRedirect,
  className,
}) => {
  const handleShowSignInModal = () => {
    AnalyticsService.trackEvent('BtnSignIn.Click', { from: 'TaskErrorWidget' })

    openSignInModal(authRedirect)
  }

  if (isAxiosError(error)) {
    if (error.response?.status === 404) {
      return (
        <div className={cx('flex max-w-[380px] flex-col items-center text-center', className)}>
          <IllustrationSpace className="mb-6 text-danger" />

          <h2 className="mb-2 font-semibold text-[#493AA8]">404 Lost in Task Space</h2>
          <p>The task you seek is in another dimension... or just not here at all.</p>
        </div>
      )
    }
    if (error.response?.status === 401) {
      return (
        <div className={cx('flex max-w-[380px] flex-col items-center text-center', className)}>
          <img src={imgReportBlured} alt="Report blured" className="mb-6" />

          <h2 className="mb-2 font-semibold">Sign In Required</h2>
          <p>To ensure secure access, please sign in to&nbsp;continue.</p>

          <Button className="mt-4 !flex w-[240px]" onClick={handleShowSignInModal}>
            Sign In
          </Button>
        </div>
      )
    }
  }

  return (
    <div className={className}>
      <p>{getErrorMessage(error) || 'Something went wrong...'}</p>
    </div>
  )
}

export default TaskErrorWidget
