import axios from 'axios'

import { AnyTaskResponse, PersonMemo, TaskType, TranscriptReport } from '@/modules/task/model'
import { ApiService } from '@/services/api'

import { anyTaskAdapter, taskInfoAdapter, taskSignedUrlAdapter } from './taskApiAdapters'
import {
  TaskInfoResponse,
  TaskSignedUrlResponse,
  TranscriptReportSignedUrlRequest,
} from './taskApiInterfaces'

export const getTasks = async () => {
  const response = await ApiService.workspaceInstance.get<TaskInfoResponse[]>('/tasks')

  return response.data.map((taskResponse) => taskInfoAdapter(taskResponse))
}
export const getTask = async (id: string) => {
  const response = await ApiService.workspaceInstance.get<AnyTaskResponse>(`/tasks/${id}`)

  return anyTaskAdapter(response.data)
}

export const deleteTask = async (id: string) => {
  await ApiService.workspaceInstance.delete<AnyTaskResponse>(`/tasks/${id}`)
}

export const regenerateTask = async (taskId: string) => {
  const response = await ApiService.workspaceInstance.post<AnyTaskResponse>(
    `/tasks/regenerate/${taskId}`,
  )

  return anyTaskAdapter(response.data)
}

export const renameTask = async (taskId: string, title: string) => {
  const response = await ApiService.workspaceInstance.post<AnyTaskResponse>(`/tasks/rename`, {
    taskId,
    title,
  })

  return anyTaskAdapter(response.data)
}

export const getPublicTask = async (taskId: string) => {
  const response = await ApiService.workspaceInstance.get<AnyTaskResponse>(
    `/tasks/public/${taskId}`,
  )

  return anyTaskAdapter(response.data)
}

export const getDemoTask = async (taskType: TaskType) => {
  const response = await ApiService.apiInstance.get<{
    shareLink: string
  }>(`/tasks/demo/${taskType}`)

  return response.data.shareLink
}

export const getAnonTasks = async () => {
  const response = await ApiService.apiInstance.get<AnyTaskResponse[]>('/tasks/anon')

  return response.data.map((taskResponse) => anyTaskAdapter(taskResponse))
}
export const getAnonTask = async (id: string) => {
  const response = await ApiService.apiInstance.get<AnyTaskResponse>(`/tasks/anon/${id}`)

  return anyTaskAdapter(response.data)
}

export const sendTaskFeedback = async (taskId: string, score: number) => {
  await ApiService.workspaceInstance.post<AnyTaskResponse>(`/tasks/feedback`, { taskId, score })
}

export const uploadFileToBucket = async (file: File, signedUrl: string) => {
  const response = await axios.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
  })

  return response.data
}

export const updateTaskAccess = async (
  taskId: string,
  access: {
    accessType: string
    emails?: string[]
    coveringLetter?: string
  },
) => {
  const response = await ApiService.workspaceInstance.post<AnyTaskResponse>(`/tasks/share`, {
    taskId,
    ...access,
  })

  return anyTaskAdapter(response.data)
}

export const addTaskToWorkspace = async (taskPublicId: string) => {
  const response = await ApiService.workspaceInstance.post<AnyTaskResponse>(
    `/tasks/public/${taskPublicId}/replicate`,
  )

  return anyTaskAdapter(response.data)
}

/* Transcript report task */
export const createSignedUrl = async (request: TranscriptReportSignedUrlRequest) => {
  const response = await ApiService.workspaceInstance.post<TaskSignedUrlResponse>(
    '/tasks/create/signed-url',
    request,
  )

  return taskSignedUrlAdapter(response.data)
}

export const createAnonSignedUrl = async (request: TranscriptReportSignedUrlRequest) => {
  const response = await ApiService.apiInstance.post<TaskSignedUrlResponse>(
    '/tasks/anon/create/signed-url',
    request,
  )

  return taskSignedUrlAdapter(response.data)
}

export const createTranscriptReport = async (taskId: string) => {
  const response = await ApiService.workspaceInstance.post<TranscriptReport.TaskResponse>(
    '/tasks/create/transcript-report',
    {
      taskId,
    },
  )

  return TranscriptReport.taskConfig.taskAdapter(response.data)
}

export const createAnonTranscriptReport = async (taskId: string) => {
  const response = await ApiService.apiInstance.post<TranscriptReport.TaskResponse>(
    '/tasks/anon/create/transcript-report',
    {
      taskId,
    },
  )

  return TranscriptReport.taskConfig.taskAdapter(response.data)
}

export const getTranscript = async (taskId: string) => {
  const response = await ApiService.workspaceInstance.get<string>(`/downloads/transcript/${taskId}`)

  return response.data
}

/* Person memo task */
export const createPersonMemo = async (profileUrl: string, context?: string) => {
  const response = await ApiService.workspaceInstance.post<PersonMemo.TaskResponse>(
    '/tasks/create/linkedin-memo',
    { linkedInUrl: profileUrl, context },
  )

  return PersonMemo.taskConfig.taskAdapter(response.data)
}
export const createAnonPersonMemo = async (profileUrl: string, context?: string) => {
  const response = await ApiService.apiInstance.post<PersonMemo.TaskResponse>(
    '/tasks/anon/create/linkedin-memo',
    { linkedInUrl: profileUrl, context },
  )

  return PersonMemo.taskConfig.taskAdapter(response.data)
}

export const autofillPersonalInfo = async (profileUrl: string, context?: string) => {
  const response = await ApiService.workspaceInstance.post<PersonMemo.TaskResponse>(
    '/tasks/create/personal-info',
    { linkedInUrl: profileUrl, context },
  )

  return PersonMemo.taskConfig.taskAdapter(response.data)
}
