import { Button } from '@onsaui'
import { IconArrowRightSm, IconCheck } from '@onsaui/icons'
import cx from 'clsx'

import { BillingPlan, BillingPlanTier } from '@/modules/billing/model'

const getPriceLabel = (plan: BillingPlan, isAnnual = false) => {
  if (plan.tier === BillingPlanTier.CUSTOM) {
    return 'Custom pricing'
  }
  if (plan.tier === BillingPlanTier.FREE) {
    return '$0/month'
  }
  return isAnnual ? `$${plan.yearlyPrice}/year` : `$${plan.monthlyPrice}/month`
}

const BillingPlanItem: React.FC<{
  plan: BillingPlan
  className?: string
  isAnnual?: boolean
  isCurrent?: boolean
  isLoading?: boolean
  onPlanSelected?: (plan: BillingPlan) => void
}> = ({ plan, className, isAnnual, isCurrent, isLoading, onPlanSelected }) => {
  const { tier } = plan

  const isFree = tier === BillingPlanTier.FREE
  const isPro = tier === BillingPlanTier.PRO
  const isCustom = tier === BillingPlanTier.CUSTOM

  const priceLabel = getPriceLabel(plan, isAnnual)

  const handlePlanSelected = () => {
    onPlanSelected?.(plan)
  }

  let button = null
  if (isCurrent) {
    button = (
      <Button className="w-full" isDisabled>
        Current Plan
      </Button>
    )
  } else if (isPro) {
    button = (
      <Button
        className="w-full"
        variant="primary"
        onClick={handlePlanSelected}
        isLoading={isLoading}
        isDisabled={isLoading}
      >
        Upgrade to Professional <IconArrowRightSm className="ml-2" />
      </Button>
    )
  } else if (isCustom) {
    button = (
      <Button
        className="w-full"
        variant="success"
        onClick={handlePlanSelected}
        isLoading={isLoading}
        isDisabled={isLoading}
      >
        Contact Sales <IconArrowRightSm className="ml-2" />
      </Button>
    )
  }

  return (
    <div
      className={cx(
        'relative w-[300px] rounded-3xl border border-disabled px-5 pb-5 pt-8',
        { 'border-primary': isPro },
        className,
      )}
    >
      {isPro && (
        <div className="absolute -right-6 top-0 -translate-y-1/2 rounded-full bg-primary px-4 py-2 text-sm text-white">
          Recommended
        </div>
      )}
      <h3 className="mb-2 text-base">{plan.name}</h3>
      <p
        className={cx('mb-8 text-h2 font-bold', {
          'text-disabled': isFree,
          'text-primary': isPro,
          'text-success': isCustom,
        })}
      >
        {priceLabel}
      </p>

      {button}

      <div className="mt-5 flex flex-col gap-2">
        {plan.details.map((detail, n) => {
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={n}
              className="flex flex-row items-center gap-2 rounded-2xl bg-disabled-surface px-5 py-4"
            >
              <IconCheck className={cx({ 'text-primary': !isFree })} />
              <span className="text-sm">{detail.content}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default BillingPlanItem
