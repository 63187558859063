/* eslint-disable class-methods-use-this */

import { TaskBaseConfig } from '../../taskConfig'
import { TaskType } from '../../taskTypeInterfaces'
import { InternalPersonInfoTaskDataResponse, TaskResponse } from './internalPersonInfoApiInterfaces'
import { InternalPersonInfoTaskData, Task } from './internalPersonInfoInterfaces'

class InternalPersonInfoTaskConfig extends TaskBaseConfig<
  TaskType.InternalPersonInfo,
  InternalPersonInfoTaskDataResponse,
  InternalPersonInfoTaskData,
  TaskResponse,
  Task
> {
  constructor() {
    super(TaskType.InternalPersonInfo)
  }

  taskDataAdapter = (responseData: InternalPersonInfoTaskDataResponse) => {
    return {}
  }
}

export const taskConfig = new InternalPersonInfoTaskConfig()
