import { Button } from '@onsaui'
import { IconPlusSm } from '@onsaui/icons'
import { Link, useParams } from 'react-router-dom'

import SidebarIcpList from '@/modules/icp/components/SidebarIcpList/SidebarIcpList'
import SidebarTaskList from '@/modules/task/components/SidebarTaskList/SidebarTaskList'
import GlobalEventService from '@/services/globalEvent'

const WorkspaceSidebar: React.FC = () => {
  const { workspaceId, taskId, icpId } = useParams()

  const handleAddNewClick = () => {
    GlobalEventService.main.emit('addNewTaskClick')
  }

  return (
    <div className="flex flex-col overflow-auto px-6">
      <Button className="sidebar-int" variant="outlined" asChild>
        <Link to={`/${workspaceId}`} onClick={handleAddNewClick}>
          <span>Add New</span>
          <IconPlusSm className="ml-2 inline-block" />
        </Link>
      </Button>

      <div className="mt-12">
        <p className="mb-1 font-bold">ICP</p>
        <SidebarIcpList className="overflow-auto" selectedIcpId={icpId} />
      </div>

      <div className="mt-4">
        <p className="mb-1 font-bold">Tasks</p>
        <SidebarTaskList className="mt-2 overflow-auto pb-12" selectedTaskId={taskId} />
      </div>
    </div>
  )
}
export default WorkspaceSidebar
