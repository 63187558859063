import { ApiService } from '@/services/api'

import { workspaceAdapter, workspaceMinimalAdapter } from './workspaceApiAdapters'
import { WorkspaceMinimalResponse, WorkspaceResponse } from './workspaceApiInterfaces'

export const getWorkspaces = async () => {
  const response = await ApiService.apiInstance.get<WorkspaceMinimalResponse[]>('workspaces')

  return response.data.map((workspaceResponse) => workspaceMinimalAdapter(workspaceResponse))
}

export const getWorkspace = async (workspaceId: string) => {
  const response = await ApiService.apiInstance.get<WorkspaceResponse>(`workspaces/${workspaceId}`)

  return workspaceAdapter(response.data)
}

export const setWorkspaceUserInfo = async (companyInfo: string, personalInfo: string) => {
  const response = await ApiService.workspaceInstance.put<WorkspaceResponse>(
    `workspaces/set-user-info`,
    {
      companyInfo,
      personalInfo,
    },
  )

  return workspaceAdapter(response.data)
}
