import { Icp, IcpMinimal } from '@/modules/icp/model'
import { ApiService } from '@/services/api'

import { icpAdapter } from './icpApiAdapters'

export const getIcps = async () => {
  const response = await ApiService.workspaceInstance.get<IcpMinimal[]>('/icps')

  return response.data
}

export const createIcp = async (linkedInUrl: string) => {
  const response = await ApiService.workspaceInstance.post<Icp>('/icps', { linkedInUrl })

  return icpAdapter(response.data)
}

export const getIcp = async (id: string) => {
  const response = await ApiService.workspaceInstance.get<Icp>(`/icps/${id}`)

  return icpAdapter(response.data)
}

export const updateIcp = async (id: string, icp: Partial<Icp>) => {
  const response = await ApiService.workspaceInstance.patch<Icp>(`/icps/${id}`, icp)

  return icpAdapter(response.data)
}

export const deleteIcp = async (id: string) => {
  await ApiService.workspaceInstance.delete(`/icps/${id}`)
}

export const regenerateIcp = async (id: string) => {
  const response = await ApiService.workspaceInstance.post<Icp>(`/icps/${id}/regenerate`)

  return icpAdapter(response.data)
}
