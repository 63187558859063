import { notify } from '@onsaui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { taskApi } from '@/modules/task/api'
import { TaskType } from '@/modules/task/model'
import AnalyticsService from '@/services/analytics'

const useDemoTask = () => {
  const navigate = useNavigate()

  const [isDemoTaskLoading, setIsLoading] = useState(false)

  const openDemoTask = async (taskType: TaskType) => {
    AnalyticsService.trackEvent('TryDemoTask.Click', { taskType })

    setIsLoading(true)

    try {
      const taskPublicUrl = await taskApi.getDemoTask(taskType)

      const url = new URL(taskPublicUrl)
      if (url.origin === window.location.origin) {
        navigate(url.pathname)
      } else {
        window.open(taskPublicUrl, '_blank')
      }
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })

      throw e
    } finally {
      setIsLoading(false)
    }
  }

  return { openDemoTask, isDemoTaskLoading }
}

export default useDemoTask
