const downloadTxt = (text: string, name: string) => {
  const blob = new Blob([text], { type: 'text/plain' })
  const url = window.URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.download = `${name}.txt`
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export default downloadTxt
