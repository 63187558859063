import { Modal } from '@onsaui'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { closeSignInModal, useAuthStore } from '@/modules/auth/store/authStore'
import AnalyticsService from '@/services/analytics'

import SignInForm from '../SignInForm/SignInForm'

const AuthModal: React.FC = () => {
  const navigate = useNavigate()

  const { isSignInModalOpen, singInModalRedirect } = useAuthStore()
  const redirect = singInModalRedirect || '/'

  const handleClose = () => {
    closeSignInModal()
  }

  const handleSignInSuccess = () => {
    AnalyticsService.trackEvent('SignInModal.Success')

    handleClose()
    navigate(redirect)
  }

  useEffect(() => {
    if (isSignInModalOpen) {
      AnalyticsService.trackEvent('SignInModal.Open')
    }
  }, [isSignInModalOpen])

  return (
    <Modal isOpen={isSignInModalOpen} onClose={handleClose} className="w-full max-w-[380px]">
      <SignInForm onSignInSuccess={handleSignInSuccess} redirect={redirect} />
    </Modal>
  )
}
export default AuthModal
