import dayjs from 'dayjs'
import Cookies from 'js-cookie'
// import { AnalyticsService } from "services/analytics";
import { v4 as uuidv4 } from 'uuid'

import { useAuthStore } from '@/modules/auth'
import { authApi } from '@/modules/auth/api'

const BASE_URL = import.meta.env.VITE_API_BASE_URL as string

const setAuth = (token: string, userId: string) => {
  Cookies.set('onsa', token)
  Cookies.set('onsa_user_id', userId)

  // AnalyticsService.setMetaData({ userId });

  useAuthStore.setState({ token, userId })
}

const initSession = () => {
  let sessionId = Cookies.get('onsa_session_id')
  if (!sessionId) {
    sessionId = uuidv4()
    Cookies.set('onsa_session_id', sessionId!)
  }

  useAuthStore.setState({ sessionId })
}

export const getAuthToken = () => {
  return useAuthStore.getState().token
}

export const getSessionId = () => {
  return useAuthStore.getState().sessionId
}

export const reviewAuth = () => {
  initSession()

  const token = Cookies.get('onsa')
  const userId = Cookies.get('onsa_user_id')

  if (token && userId) {
    setAuth(token, userId)
  }
}

export const dropAuth = () => {
  Cookies.remove('onsa')
  Cookies.remove('onsa_user_id')

  // AnalyticsService.sendEvent("auth_drop");
  // AnalyticsService.setMetaData({ userId: "anonymous" });

  useAuthStore.setState({ token: null, userId: null })
}

export const authViaCode = async (code: string) => {
  const { accessToken, userId } = await authApi.authViaCode(code, dayjs.tz.guess())

  // AnalyticsService.setMetaData({ userId });
  // AnalyticsService.sendEvent("auth_via_code");

  setAuth(accessToken, userId)
}

export const authViaToken = (token: string, userId: string) => {
  // AnalyticsService.setMetaData({ userId });
  // AnalyticsService.sendEvent("auth_via_link");

  setAuth(token, userId)
}

export const subscribeOnDropAuth = (callback: () => void) => {
  useAuthStore.subscribe((state, prevState) => {
    if (prevState.token && !state.token) {
      callback()
    }
  })
}
export const subscribeOnAuth = (callback: (token: string, userId: string) => void) => {
  useAuthStore.subscribe((state, prevState) => {
    if (!prevState.token && state.token && state.userId) {
      callback(state.token, state.userId)
    }
  })
}

export const getGoogleAuthUrl = () => {
  return `${BASE_URL}/api/auth/google?session-id=${getSessionId()}`
}
