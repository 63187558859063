import { BillingPlan } from '@/modules/billing/model'

import { BillingPlanResponse } from './billingApiInterfaces'

export const billingPlanAdapter = (billingPlanResponse: BillingPlanResponse) => {
  const billingPlan: BillingPlan = {
    id: billingPlanResponse.id,
    name: billingPlanResponse.name,
    details: billingPlanResponse.details,
    monthlyPrice: billingPlanResponse.monthlyPrice,
    yearlyPrice: billingPlanResponse.yearlyPrice,
    currency: billingPlanResponse.currency,
    tier: billingPlanResponse.tier,
    minSeats: billingPlanResponse.minSeats,
    maxSeats: billingPlanResponse.maxSeats,
    isCurrent: billingPlanResponse.isCurrent,
  }

  return billingPlan
}
