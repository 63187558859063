import { useIsDesktopScreen } from '@onsaui'
import cx from 'clsx'
import React, { useRef, useState } from 'react'

import { IconSidebar } from '@/onsaui/icons'

import cm from './GeneralLayout.module.css'

const GeneralLayout: React.FC<{
  sidebar?: React.ReactNode
  header?: React.ReactNode
  body?: React.ReactNode
  noSidebar?: boolean
}> = ({ sidebar, header, body, noSidebar }) => {
  const isDesktop = useIsDesktopScreen()

  const [isSidebarOpen, setIsSidebarOpen] = useState(isDesktop)
  const [isFirstOpen, setIsFirstOpen] = useState(isDesktop)

  const toggleSidebar = () => {
    setIsFirstOpen(false)
    setIsSidebarOpen(!isSidebarOpen)
  }

  const sidebarRef = useRef<HTMLDivElement>(null)
  const handleClickOnElement = (el: HTMLElement) => {
    const sidebarEl = sidebarRef.current
    if (!sidebarEl) {
      return
    }

    const interactiveElements = sidebarEl.getElementsByClassName('sidebar-int')
    const isInteractiveElement = Array.from(interactiveElements).some((element) =>
      element.contains(el),
    )

    if (isInteractiveElement) {
      setTimeout(() => toggleSidebar(), 10)
    }
  }
  const handleSidebarClick = (e: React.MouseEvent) => {
    if (!isDesktop) {
      handleClickOnElement(e.target as HTMLElement)
    }
  }

  return (
    <div className="relative flex max-h-full min-h-full w-full flex-row">
      {isSidebarOpen && !noSidebar && (
        <>
          {!isDesktop && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div className="fixed inset-0 bg-black/5" onClick={toggleSidebar} />
          )}

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */}
          <aside
            className={cx(
              'flex w-[320px] min-w-[320px] shrink-0 flex-col bg-level1',
              {
                'absolute left-0 top-0 z-20 h-full shadow-2xl': !isDesktop,
              },
              !isFirstOpen && cm.slideFromLeft,
            )}
            ref={sidebarRef}
            onClick={handleSidebarClick}
          >
            <div className="flex min-h-[124px] flex-row items-center px-6">
              <button type="button" onClick={toggleSidebar} aria-label="Close sidebar">
                <IconSidebar />
              </button>
            </div>
            {sidebar}
          </aside>
        </>
      )}
      <main className="flex min-h-full flex-grow flex-col overflow-auto bg-[#EFF2F6] mobile:w-full">
        <div className="flex min-h-[124px] shrink-0 flex-row items-center px-6">
          {!isSidebarOpen && !noSidebar && (
            <button
              type="button"
              className="mr-4"
              onClick={toggleSidebar}
              aria-label="Open sidebar"
            >
              <IconSidebar />
            </button>
          )}
          {header}
        </div>

        {body}
      </main>
    </div>
  )
}

export default GeneralLayout
