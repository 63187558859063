import { Modal } from '@onsaui'
import { useEffect } from 'react'

import {
  closeSystemPromptSettingsModal,
  useWorkspaceStore,
} from '@/modules/workspace/store/workspaceStore'
import AnalyticsService from '@/services/analytics'

import SystemPromptSettingsForm from '../SystemPromptSettingsForm/SystemPromptSettingsForm'

const SystemPromptSettingsModal: React.FC = () => {
  const { isSystemPromptSettingsModalOpen } = useWorkspaceStore()

  const handleClose = () => {
    closeSystemPromptSettingsModal()
  }

  useEffect(() => {
    if (isSystemPromptSettingsModalOpen) {
      AnalyticsService.trackEvent('SystemPromptSettingsModal.Open')
    }
  }, [isSystemPromptSettingsModalOpen])

  return (
    <Modal className="max-w-xl" isOpen={isSystemPromptSettingsModalOpen} onClose={handleClose}>
      <SystemPromptSettingsForm onCancel={handleClose} />
    </Modal>
  )
}

export default SystemPromptSettingsModal
