/* eslint-disable class-methods-use-this */
import { TaskBaseConfig } from '../../taskConfig'
import { TaskType } from '../../taskTypeInterfaces'
import { TaskResponse, TranscriptReportTaskDataResponse } from './transcriptReportApiInterfaces'
import { Task, TranscriptReportTaskData } from './transcriptReportInterfaces'

class TranscriptReportTaskConfig extends TaskBaseConfig<
  TaskType.TranscriptReport,
  TranscriptReportTaskDataResponse,
  TranscriptReportTaskData,
  TaskResponse,
  Task
> {
  constructor() {
    super(TaskType.TranscriptReport)
  }

  taskDataAdapter = (responseData: TranscriptReportTaskDataResponse) => {
    const { response } = responseData

    const data: TranscriptReportTaskData = {
      ...response,
    }

    return data
  }
}

export const taskConfig = new TranscriptReportTaskConfig()
