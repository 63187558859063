import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { authActions } from '@/modules/auth'

const AuthRedirectPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const sign = searchParams.get('sign')
  const userId = searchParams.get('userId')
  const redirect = searchParams.get('redirect') || '/'

  const navigate = useNavigate()

  useEffect(() => {
    if (!sign || !userId) {
      return navigate('/')
    }

    authActions.authViaToken(sign, userId)

    navigate(redirect)
  }, [navigate, sign, userId, redirect])

  return <div>Redirect...</div>
}

export default AuthRedirectPage
