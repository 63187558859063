import { create } from 'zustand'

export interface WorkspaceStore {
  isSystemPromptSettingsModalOpen: boolean
}

export const useWorkspaceStore = create<WorkspaceStore>((set) => ({
  isSystemPromptSettingsModalOpen: false,
}))

export const openSystemPromptSettingsModal = () => {
  useWorkspaceStore.setState({ isSystemPromptSettingsModalOpen: true })
}
export const closeSystemPromptSettingsModal = () => {
  useWorkspaceStore.setState({ isSystemPromptSettingsModalOpen: false })
}
