import { Button, Modal } from '@onsaui'
import { IllustrationDashboard } from '@onsaui/icons'
import { useEffect } from 'react'

import {
  closeUpgradeToProPromoModal,
  openUpgradeToProModal,
  useBillingStore,
} from '@/modules/billing/store/billingStore'
import AnalyticsService from '@/services/analytics'
import { SUPPORT_EMAIL } from '@/shared/constants/supportEmail'

const UpgradeToProPromoModal: React.FC = () => {
  const { isUpgradeToProPromoModalOpen } = useBillingStore()

  const handleUpgradeClick = () => {
    AnalyticsService.trackEvent('BtnUpgradeToPro.Click', { from: 'UpgradeToProPromoModal' })

    openUpgradeToProModal()
    closeUpgradeToProPromoModal()
  }

  useEffect(() => {
    if (isUpgradeToProPromoModalOpen) {
      AnalyticsService.trackEvent('UpgradeToProPromoModal.Open')
    }
  }, [isUpgradeToProPromoModalOpen])

  return (
    <Modal
      className="max-w-sm"
      isOpen={isUpgradeToProPromoModalOpen}
      onClose={closeUpgradeToProPromoModal}
    >
      <IllustrationDashboard className="m-auto mb-5" />
      <h2 className="mb-5 text-center">Upgrade to continue</h2>
      <p className="mb-5 text-center">
        It looks like you&apos;ve reached the limit of your current plan. To access this feature and
        continue enjoying our services, you&apos;ll need to upgrade your plan.
      </p>

      <Button className="mb-2 w-full" onClick={handleUpgradeClick}>
        Upgrade
      </Button>
      <Button className="w-full" variant="ghost" asChild>
        <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noreferrer">
          Contact support
        </a>
      </Button>
    </Modal>
  )
}
export default UpgradeToProPromoModal
