import EmojiAvatar from '@/shared/component/EmojiAvatar/EmojiAvatar'

const UserAvatar: React.FC<{ avatarUrl?: string; className?: string }> = ({
  avatarUrl,
  className,
}) => {
  if (!avatarUrl) {
    return <EmojiAvatar className={className} />
  }

  return <img src={avatarUrl} alt="User avatar" className={className} />
}

export default UserAvatar
