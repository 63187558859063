import { Loader } from '@onsaui'
import { IllustrationBookLoading } from '@onsaui/icons'
import cx from 'clsx'
import { useEffect } from 'react'

import useTask from '@/modules/task/hooks/useTask'
import useTaskPolling from '@/modules/task/hooks/useTaskPoling'

const AutofillStatus: React.FC<{
  taskId: string
  onFinished?: () => void
  className?: string
}> = ({ taskId, onFinished, className }) => {
  const { data: task } = useTask(taskId)
  useTaskPolling(taskId)

  useEffect(() => {
    if (task?.status === 'completed' || task?.status === 'failed') {
      onFinished?.()
    }
  }, [task, onFinished])

  return (
    <div
      className={cx('flex max-w-md flex-col items-center justify-center text-center', className)}
    >
      <IllustrationBookLoading className="mb-8" />

      <h2 className="mb-2">Customizing your profile info</h2>
      <p className="mb-8">
        We&apos;re tailoring your profile info based on your LinkeIn. This process might take a few
        minutes.
      </p>

      <Loader />
    </div>
  )
}

export default AutofillStatus
