import cx from 'clsx'

import { AnyTask, TaskContext } from '@/modules/task/model'

import TaskToolbar, { TaskTool } from '../TaskToolbar/TaskToolbar'
import TitleEditor from './TitleEditor'

const TaskHeader: React.FC<{ task: AnyTask; taskContext?: TaskContext; className?: string }> = ({
  task,
  taskContext,
  className,
}) => {
  const tools: TaskTool[] = [{ type: 'copy' }]
  if (taskContext?.withRename) {
    tools.unshift({ type: 'rename' })
  }
  if (taskContext?.withShare) {
    tools.push({ type: 'share' })
  }
  if (taskContext?.withRegenerate) {
    tools.push({ type: 'regenerate' })
  }
  if (taskContext?.withAddToWorkspace) {
    tools.push({ type: 'addToWorkspace', taskPublicId: taskContext.taskPublicId! })
  }

  return (
    <div className={cx('flex flex-row items-center gap-4', className)}>
      <TitleEditor task={task} isNoEdit={!taskContext?.withRename} />

      <div className="flex-grow" />

      <TaskToolbar task={task} tools={tools} className="" />
    </div>
  )
}

export default TaskHeader
