import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import cx from 'clsx'
import React from 'react'

import Loader from '../Loader/Loader'
import cm from './Button.module.css'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap transition-all duration-200',
  {
    variants: {
      isLoading: {
        true: '',
        false: '',
      },
      isDisabled: {
        true: '',
        false: '',
      },
      variant: {
        primary: 'rounded text-white',
        danger: 'rounded text-white',
        success: 'rounded text-white',
        outlined: 'bg-transparent border border-contrast rounded text-default',
        ghost: 'bg-transparent rounded',
      },
      size: {
        default: 'min-h-[60px] px-5 text-base',
        medium: 'min-h-[48px] px-5 text-base',
      },
    },
    compoundVariants: [
      /* Primary */
      {
        variant: ['primary'],
        isDisabled: false,
        className: cx('bg-primary active:bg-[#0146C1]', cm.primaryHoverShadow),
      },
      {
        variant: ['primary'],
        isDisabled: true,
        className: 'bg-disabled',
      },

      /* Danger */
      {
        variant: ['danger'],
        isDisabled: false,
        className: cx('bg-danger', cm.dangerHoverShadow),
      },
      {
        variant: ['danger'],
        isDisabled: true,
        className: 'bg-disabled',
      },

      /* Success */
      {
        variant: ['success'],
        isDisabled: false,
        className: cx('bg-success', cm.successHoverShadow),
      },
      {
        variant: ['success'],
        isDisabled: true,
        className: 'bg-disabled',
      },

      /* Outlined */
      {
        variant: ['outlined'],
        isDisabled: false,
        className: cx(cm.outlinedHoverShadow),
      },
      {
        variant: ['outlined'],
        isDisabled: true,
        className: 'border-disabled text-disabled',
      },

      /* Ghost */
      {
        variant: ['ghost'],
        size: ['default'],
        className: '!h-[40px]',
      },
      {
        variant: ['ghost'],
        isDisabled: false,
        className: cx('text-primary active:text-[#0146C1]', cm.primaryHoverTextShadow),
      },
      {
        variant: ['ghost'],
        isDisabled: true,
        className: 'text-disabled',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'default',
      isLoading: false,
      isDisabled: false,
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, isDisabled = false, isLoading = false, asChild = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        ref={ref}
        className={cx(buttonVariants({ variant, size, isDisabled, isLoading, className }))}
        disabled={isDisabled ?? undefined}
        {...props}
        // eslint-disable-next-line react/no-children-prop
        children={isLoading ? <Loader sm /> : props.children}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
