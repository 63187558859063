import { ApiService } from '@/services/api'

export const generateMemo = async (userDetails: string, callDetails?: string) => {
  const response = await ApiService.apiInstance.post<{
    html: string
    css: string
  }>('/ai-test/request', {
    userDetails,
    callDetails,
  })

  return response.data
}

// const sendRequest = async (text: string) => {
//     const result = await fetch("https://api.openai.com/v1/chat/completions", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization:
//           "Bearer sk-proj-lbfZXyUzCqMdNLeyn8l4T3BlbkFJPSXsvMdrfVXsY8juUcV0",
//       },
//       body: JSON.stringify({
//         model: "gpt-4o",
//         messages: [
//           {
//             role: "user",
//             content: "Tell me a funny joke",
//           },
//         ],
//       }),
//     });
//     const body = await result.json();

//     console.log(result, body);
//   };
