import { useParams } from 'react-router-dom'

import GeneralLayout from '@/modules/general/components/GeneralLayout/GeneralLayout'
import WorkspaceHeader from '@/modules/general/components/WorkspaceHeader/WorkspaceHeader'
import WorkspaceSidebar from '@/modules/general/components/WorkspaceSidebar/WorkspaceSidebar'
import OutreachTemplatesWidget from '@/modules/icp/components/OutreachTemplatesWidget/OutreachTemplatesWidget'

const OutreachTemplatesPage: React.FC = () => {
  const { icpId } = useParams()

  return (
    <GeneralLayout
      sidebar={<WorkspaceSidebar />}
      header={<WorkspaceHeader />}
      body={
        <div className="mx-auto flex w-full max-w-5xl flex-grow flex-col px-6 pb-8">
          <OutreachTemplatesWidget className="flex-grow" icpId={icpId!} />
        </div>
      }
    />
  )
}

export default OutreachTemplatesPage
