export enum TaskAccessType {
  Private = 'private',
  Invite = 'invite',
  Public = 'public',
}

export interface TaskAccess {
  accessType: TaskAccessType
  shareLink: string | null
  invites: string[]
}
