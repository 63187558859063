import { Modal } from '@onsaui'
import cx from 'clsx'
import { useEffect, useState } from 'react'

import { closePlanSelectModal, useBillingStore } from '@/modules/billing/store/billingStore'
import AnalyticsService from '@/services/analytics'

import BillingPlanSelect from '../BillingPlanSelect/BillingPlanSelect'

const BillingPlanSelectModal: React.FC = () => {
  const { isPlanSelectModalOpen } = useBillingStore()

  const [isAnnual, setIsAnnual] = useState(false)

  const tabClassName = (isSelected: boolean) => {
    return cx('px-4 py-2.5 text-center flex-1 text-sm w-1/2 outline-none', {
      'bg-primary text-white': isSelected,
      'text-primary ': !isSelected,
    })
  }

  const handlePlanUpgraded = () => {
    closePlanSelectModal()
  }

  useEffect(() => {
    if (isPlanSelectModalOpen) {
      AnalyticsService.trackEvent('PlanSelectModal.Open')
    }
  }, [isPlanSelectModalOpen])

  return (
    <Modal className="min-w-[1020px]" isOpen={isPlanSelectModalOpen} onClose={closePlanSelectModal}>
      <h2 className="mb-12 text-center">Choose a plan that fits your needs</h2>

      {/* <div className="m-auto mb-16 w-[320px] overflow-hidden rounded-full border border-primary">
        <button type="button" className={tabClassName(isAnnual)} onClick={() => setIsAnnual(true)}>
          Yearly (15% profit)
        </button>
        <button
          type="button"
          className={tabClassName(!isAnnual)}
          onClick={() => setIsAnnual(false)}
        >
          Monthly
        </button>
      </div> */}

      <BillingPlanSelect className="mt-4" isAnnual={isAnnual} onPlanUpgraded={handlePlanUpgraded} />
    </Modal>
  )
}
export default BillingPlanSelectModal
