import { notify } from '@onsaui'
import { isAxiosError } from 'axios'
import { useState } from 'react'

import { icpApi } from '@/modules/icp/api'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import AnalyticsService from '@/services/analytics'

const useCreateIcp = (onLimitExeededError?: () => void) => {
  const [isCreating, setIsCreating] = useState(false)

  const createIcp = async (personUrl: string) => {
    AnalyticsService.trackEvent('CreateIcp.Start')

    setIsCreating(true)
    try {
      const icp = await icpApi.createIcp(personUrl)

      AnalyticsService.trackEvent('CreateIcp.Success', {
        id: icp.id,
      })

      return icp
    } catch (e) {
      let isHandled = false
      if (isAxiosError(e)) {
        if (e.response?.status === 402) {
          isHandled = true
          AnalyticsService.trackEvent('CreateIcp.ErrorLimitExeeded')
          notify({ message: getErrorMessage(e), variant: 'error' })
          onLimitExeededError?.()
        }
        if (e.response?.status === 429) {
          isHandled = true
          notify({ message: getErrorMessage(e), variant: 'error' })
        }
      }

      if (!isHandled) {
        AnalyticsService.trackEvent('CreateIcp.Error', {
          message: getErrorMessage(e),
        })

        notify({ message: getErrorMessage(e), variant: 'error' })
      }

      throw e
    } finally {
      setIsCreating(false)
    }
  }

  return { isCreating, createIcp }
}

export default useCreateIcp
