import { OnsaLogo } from '@onsaui'

import ProfileMenu from '@/modules/user/components/ProfileMenu/ProfileMenu'

const WorkspaceHeader: React.FC = () => {
  return (
    <>
      <OnsaLogo />
      <div className="flex-grow" />
      <ProfileMenu />
    </>
  )
}
export default WorkspaceHeader
