import { Button, Modal } from '@onsaui'
import { IllustrationWarning } from '@onsaui/icons'

import { openSignInModal } from '@/modules/auth/store/authStore'

const SignInOrLostProgressModal: React.FC<{
  isOpen?: boolean
  onProceed: () => void
  onClose: () => void
}> = ({ isOpen, onProceed, onClose }) => {
  const handleSignInClick = () => {
    openSignInModal()
    onClose()
  }

  return (
    <Modal className="max-w-sm" isOpen={isOpen} onClose={onClose}>
      <IllustrationWarning className="m-auto mb-5" />
      <h2 className="mb-5 text-center">Content Will Be Lost</h2>
      <p className="mb-2 text-center">
        Please note that your current content will disappear if you stay logged out.
      </p>
      <p className="mb-5 text-center text-primary">
        Sign In before proceeding to save your history.
      </p>

      <Button className="mb-5 w-full" onClick={handleSignInClick}>
        Sign In
      </Button>
      <Button className="mb-5 w-full" variant="outlined" onClick={onProceed}>
        Continue
      </Button>
    </Modal>
  )
}
export default SignInOrLostProgressModal
