import { Loader } from '@onsaui'
import { withErrorBoundary } from '@sentry/react'

import TaskErrorState from '@/modules/task/components/TaskErrorState/TaskErrorState'
import { AnyTask, TaskContext, TaskStatus, TaskType } from '@/modules/task/model'

import PersonMemoPage from './PersonMemoPage'
import TranscriptReportPage from './TranscriptReportPage'

const TaskPage: React.FC<{ task: AnyTask; taskContext?: TaskContext }> = ({
  task,
  taskContext,
}) => {
  if (task.status === TaskStatus.Completed) {
    if (task.type === TaskType.TranscriptReport) {
      return (
        <TranscriptReportPage
          task={task}
          taskContext={taskContext}
          className="mx-auto w-full max-w-5xl px-6 pb-8"
        />
      )
    }
    if (task.type === TaskType.PersonMemo) {
      return (
        <PersonMemoPage
          task={task}
          taskContext={taskContext}
          className="mx-auto w-full max-w-5xl px-6 pb-8"
        />
      )
    }
    return <p className="px-6 text-danger">Unknown task type...</p>
  }

  if (task.status === TaskStatus.Failed) {
    return (
      <div className="flex flex-grow flex-col items-center justify-center px-6">
        <TaskErrorState task={task} />
      </div>
    )
  }

  return (
    <div className="flex flex-grow flex-col items-center justify-center px-6 text-center">
      <Loader className="mb-6" />
      <p className="mb-1">
        We are generating your report, it could take up to
        {task.type === TaskType.PersonMemo ? ' 2 minutes' : ' 10 minutes'}
      </p>
      <p>Good things take time 🪄</p>
    </div>
  )
}

export default withErrorBoundary(TaskPage, {
  fallback: ({ error }) => (
    <div className="flex flex-grow flex-col items-center justify-center px-6 text-center">
      <h2 className="mb-2">Unexpected page crash 😵</h2>
      <p className="max-w-[340px]">
        Our team has been notified and will fix it as soon as possible.
      </p>
    </div>
  ),
})
