import { Button, Input, notify } from '@onsaui'
import { IconInfo, IconPlusSm, IconTrash } from '@onsaui/icons'
import { useRef } from 'react'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { taskActions } from '@/modules/task/actions'
import { TaskAccess } from '@/modules/task/model'

const PeopleForm: React.FC<{ taskId: string; access: TaskAccess }> = ({ taskId, access }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleAdd = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const email = formData.get('email') as string

    const updatedAccess = { ...access, invites: [...access.invites, email] }

    e.currentTarget.reset()
    setTimeout(() => {
      inputRef.current?.focus()
    })

    try {
      await taskActions.updateTaskAccess(taskId, updatedAccess)

      notify({ message: 'Updated', variant: 'success' })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
    }
  }

  const handleRemoveEmail = async (email: string) => {
    const updatedAccess = { ...access, invites: access.invites.filter((e) => e !== email) }

    try {
      await taskActions.updateTaskAccess(taskId, updatedAccess)

      notify({ message: 'Updated', variant: 'success' })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
    }
  }

  return (
    <div className="">
      <p className="mb-2">People with access</p>

      <form className="mb-4 flex flex-row gap-2" onSubmit={handleAdd}>
        <Input
          className="flex-grow"
          name="email"
          placeholder="Invite people by email"
          ref={inputRef}
        />

        <Button className="!max-h-12 !min-h-12" aria-label="Add email">
          <IconPlusSm />
        </Button>
      </form>

      {access.invites.length ? (
        <div className="flex flex-col">
          {access.invites.map((email, n) => (
            <div key={n} className="flex flex-row items-center justify-between gap-2 py-2">
              <p>{email}</p>
              <button
                type="button"
                className="flex h-10 w-10 items-center justify-center text-secondary hover:text-danger"
                aria-label="Remove user"
                onClick={() => handleRemoveEmail(email)}
              >
                <IconTrash className="h-6 w-6" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-row gap-2 rounded-2xl bg-disabled-surface p-5 text-secondary">
          <IconInfo className="mt-1 h-4 w-4 shrink-0" />
          <div>
            <p className="mb-1">No People with access</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default PeopleForm
