import { Link } from 'react-router-dom'

import { ReactComponent as Logo } from './onsa-logo.svg'

const OnsaLogo: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <Link to="/" className={className}>
      <Logo />
    </Link>
  )
}

export default OnsaLogo
