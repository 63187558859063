import dayjs from 'dayjs'

import { TaskAccess, TaskAccessType } from './taskAccessInterfaces'
import { GenericTask, GenericTaskResponse } from './taskGenericInterfaces'
import { TaskType } from './taskTypeInterfaces'

export const taskAccessAdapter = (anyTask: {
  accessType?: TaskAccessType
  shareLink?: string | null
  invites?: string[]
}) => {
  if (anyTask.accessType === undefined) {
    return null
  }

  const taskAccess: TaskAccess = {
    accessType: anyTask.accessType!,
    shareLink: anyTask.shareLink!,
    invites: anyTask.invites!,
  }

  return taskAccess
}

export const baseTaskAdapter = <TType extends TaskType = TaskType>(
  taskResponse: GenericTaskResponse<TType, unknown>,
) => {
  const task: GenericTask<TaskType, unknown> = {
    id: taskResponse.id,
    type: taskResponse.type,
    status: taskResponse.status,
    createdAt: dayjs(taskResponse.created_at),
    title: taskResponse.title,

    access: taskAccessAdapter(taskResponse),

    responseData: taskResponse.responseData,
  } as GenericTask<TaskType, unknown>

  return task
}
