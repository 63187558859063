import { useQuery } from '@tanstack/react-query'

import { taskApi } from '@/modules/task/api'

const usePublicTask = (taskId: string) => {
  return useQuery({
    queryKey: ['task', taskId],
    queryFn: () => {
      return taskApi.getPublicTask(taskId)
    },
  })
}

export default usePublicTask
