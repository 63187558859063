import { useQuery } from '@tanstack/react-query'

import { billingApi } from '@/modules/billing/api'

const useBillingPlans = () => {
  return useQuery({
    queryKey: ['billing_plans'],
    queryFn: () => {
      return billingApi.getBillingPlans()
    },
  })
}

export default useBillingPlans
