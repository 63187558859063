import { Button, InfoTooltip } from '@onsaui'
import { IconExit, IconInfo, IconLifebuoy, IconMagicWand, IconPersonAvatar } from '@onsaui/icons'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import cx from 'clsx'
import { Link } from 'react-router-dom'

import { BillingPlanTier } from '@/modules/billing/model'
import { openUpgradeToProModal } from '@/modules/billing/store/billingStore'
import useUserMe from '@/modules/user/hooks/useUserMe'
import { openSystemPromptSettingsModal } from '@/modules/workspace/store/workspaceStore'
import AnalyticsService from '@/services/analytics'
import { QueryService } from '@/services/query'
import { SUPPORT_EMAIL } from '@/shared/constants/supportEmail'

import TierBadge from './TierBadge'
import UserAvatar from './UserAvatar'

const ProfileMenu: React.FC<{ className?: string }> = ({ className }) => {
  const { data: userMe, isPending, isError } = useUserMe()

  const handleOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      AnalyticsService.trackEvent('ProfileMenu.Open')

      QueryService.getClient().invalidateQueries({ queryKey: ['user_me'] })
    }
  }

  const menuItemClass =
    'flex h-[100px] flex-row items-center px-5 outline-none hover:text-primary focus-visible:text-primary gap-3 w-full text-left'

  const avatarPlaceholder = (
    <button type="button" className={cx('outline-none', className)} aria-label="Profile menu">
      <div className="h-10 w-10 animate-pulse rounded-full bg-disabled" />
    </button>
  )

  const handleOpenPlanSelectModal = (from?: string) => {
    if (userMe?.planTier === BillingPlanTier.FREE) {
      AnalyticsService.trackEvent('BtnUpgradeToPro.Click', { from })
    }

    setTimeout(() => openUpgradeToProModal())
  }
  const handleOpenSystemPromptSettingsModal = () => {
    AnalyticsService.trackEvent('BtnCustomizePrompt.Click', { from: 'ProfileMenu' })

    setTimeout(() => openSystemPromptSettingsModal())
  }

  const handleSupportClick = () => {
    AnalyticsService.trackEvent('BtnContactSupport.Click', { from: 'ProfileMenu' })
  }

  if (isPending) {
    return avatarPlaceholder
  }

  if (isError) {
    return (
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>{avatarPlaceholder}</DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Content
          className="min-w-[320px] rounded-3xl bg-level1 px-0 pb-3 pt-10 shadow-lg"
          side="bottom"
          align="end"
          sideOffset={24}
        >
          <DropdownMenuPrimitive.Label asChild>
            <div className="flex flex-col items-center">
              <UserAvatar className="mb-2 h-[72px] w-[72px] rounded-full text-2xl" />

              <p className="text-sm text-danger">Unable to load profile...</p>
            </div>
          </DropdownMenuPrimitive.Label>
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    )
  }

  return (
    <DropdownMenuPrimitive.Root onOpenChange={handleOpenChange}>
      <div className={cx('flex flex-row items-center gap-2', className)}>
        <InfoTooltip
          align="end"
          side="bottom"
          content={
            <div className="w-[280px] rounded-2xl border border-default bg-level1 px-5 pb-5 pt-8 shadow-md">
              <p className="mb-3">
                Plan: <span className="text-primary">{userMe.planName}</span>
              </p>
              <p className="mb-5">
                Balance:{' '}
                <span className="text-primary">
                  <span className="font-bold">{userMe.creditsLeft}</span> credits
                </span>
              </p>
              <hr className="mb-3 border-disabled" />

              <p className="mb-3 text-sm text-secondary">
                We will top up your balance on {userMe.creditsResetDate.format('DD.MM.YYYY')}.
                {userMe?.planTier === BillingPlanTier.FREE && // Only show this part for free users
                  'Upgrade your plan to get more credits now'}
              </p>

              <Button
                className="w-full"
                onClick={() => handleOpenPlanSelectModal('ProfileTooltip')}
              >
                {userMe.planTier === BillingPlanTier.FREE ? 'Upgrade' : 'See All Plans'}
              </Button>
            </div>
          }
        >
          <div className="flex flex-row items-center gap-1">
            <IconInfo className="hidden md:inline-block" />
            <p className="text-sm">
              <span className="font-bold">{userMe.creditsLeft}</span> credits
            </p>
          </div>
        </InfoTooltip>

        <DropdownMenuPrimitive.Trigger asChild>
          <button type="button" className={cx('outline-none')} aria-label="Profile menu">
            <UserAvatar className="h-10 w-10 rounded-full" avatarUrl={userMe.avatarUrl} />
          </button>
        </DropdownMenuPrimitive.Trigger>
      </div>

      <DropdownMenuPrimitive.Content
        className="z-20 min-w-[320px] rounded-3xl bg-level1 px-0 pb-3 pt-10 shadow-lg"
        side="bottom"
        align="end"
        sideOffset={24}
      >
        <DropdownMenuPrimitive.Label asChild>
          <div className="flex flex-col items-center">
            <div className="relative">
              <UserAvatar
                className="mb-2 h-[72px] w-[72px] rounded-full text-3xl"
                avatarUrl={userMe.avatarUrl}
              />
              {userMe.planTier === BillingPlanTier.FREE ? (
                <TierBadge type="free" className="absolute -right-4 -top-2" />
              ) : (
                <TierBadge type="pro" className="absolute -right-4 -top-2" />
              )}
            </div>
            <p className="text-sm text-secondary">{userMe.email}</p>
          </div>
        </DropdownMenuPrimitive.Label>

        <DropdownMenuPrimitive.Item asChild>
          <button
            type="button"
            className={menuItemClass}
            onClick={() => handleOpenPlanSelectModal('ProfileMenu')}
          >
            <IconPersonAvatar />
            <div>
              <p>{userMe.planName}</p>
              <p className="text-sm text-primary">
                Credits left: <span className="font-bold">{userMe.creditsLeft}</span>
              </p>
            </div>
            <div className="flex-grow" />
            <div className="flex flex-row items-center rounded-2xl bg-primary px-3 py-2 text-white">
              <span className="text-sm">
                {userMe.planTier === BillingPlanTier.FREE ? 'Upgrade' : 'See All Plans'}
              </span>
            </div>
          </button>
        </DropdownMenuPrimitive.Item>
        <hr className="border-disabled" />
        <DropdownMenuPrimitive.Item asChild>
          <button
            type="button"
            className={menuItemClass}
            onClick={handleOpenSystemPromptSettingsModal}
          >
            <IconMagicWand />
            <div>
              <p>Customize Prompt</p>
            </div>
          </button>
        </DropdownMenuPrimitive.Item>
        <hr className="border-disabled" />
        <DropdownMenuPrimitive.Item asChild>
          <a
            className={menuItemClass}
            href={`mailto:${SUPPORT_EMAIL}`}
            target="_blank"
            rel="noreferrer"
            onClick={handleSupportClick}
          >
            <IconLifebuoy />
            <div>
              <p>Contact Support</p>
              <p className="text-sm text-secondary">{SUPPORT_EMAIL}</p>
            </div>
          </a>
        </DropdownMenuPrimitive.Item>
        <hr className="border-disabled" />
        <DropdownMenuPrimitive.Item asChild>
          <Link to="/sign-out" className={menuItemClass}>
            <IconExit />
            <span>Sign Out</span>
          </Link>
        </DropdownMenuPrimitive.Item>
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Root>
  )
}
export default ProfileMenu
