export enum TaskType {
  TranscriptReport = 'transcript_report',
  PersonMemo = 'linkedin-memo',
  InternalPersonInfo = 'internal-person-info',
}

export enum TaskStatus {
  Created = 'created',
  Pending = 'pending',
  Processing = 'processing',
  Failed = 'failed',
  Canceled = 'canceled',
  Completed = 'completed',
}
