import { workspaceApi } from '@/modules/workspace/api'
import { QueryService } from '@/services/query'

export const setWorkspaceUserInfo = async (companyInfo: string, personalInfo: string) => {
  const currentWorkspaceId = window.location.pathname.split('/')[1] ?? null
  const updatedWorkspace = await workspaceApi.setWorkspaceUserInfo(companyInfo, personalInfo)

  QueryService.getClient().setQueryData(['workspace', currentWorkspaceId], () => updatedWorkspace)
}

export const invalidateWorkspace = (workspaceId: string) => {
  QueryService.getClient().invalidateQueries({ queryKey: ['workspace', workspaceId] })
}
