import { useLocation } from 'react-router-dom'

import useAuth from '@/modules/auth/hooks/useAuth'

import NavigateToWorkspace from '../NavigateToWorkspace/NavigateToWorkspace'

const RedirectInsideWorkspace: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { pathname } = useLocation()
  const { isAuth } = useAuth()

  if (!isAuth) {
    return children
  }

  return <NavigateToWorkspace path={pathname} />
}

export default RedirectInsideWorkspace
