import { BillingPlan, BillingPlanTier } from '@/modules/billing/model'
import { ApiService } from '@/services/api'

import { billingPlanAdapter } from './billingApiAdapters'
import { BillingPlanResponse } from './billingApiInterfaces'

const mockBillingPlanResponse: BillingPlanResponse = {
  id: '1',
  name: 'Pro',
  details: [
    {
      content: 'Unlimited',
    },
    {
      content: 'Support 24/7',
    },
  ],
  monthlyPrice: 10,
  yearlyPrice: 100.99,
  currency: 'usd',
  tier: BillingPlanTier.PRO,
  minSeats: 1,
  maxSeats: 10,
  isCurrent: false,
}

export const getBillingPlans = async (): Promise<BillingPlan[]> => {
  const response =
    await ApiService.workspaceInstance.get<BillingPlanResponse[]>('/workspaces/plans')

  return response.data.map((billingPlanResponse) => billingPlanAdapter(billingPlanResponse))
}

export const generatePaymentLink = async (planId: string, isAnnual: boolean, seats: number) => {
  const response = await ApiService.workspaceInstance.post<{
    type: 'Stripe'
    url: string
    expire: number
  }>(`payments/create`, {
    planId,
    period: isAnnual ? 'yearly' : 'monthly',
    seats,
  })

  return response.data
}

export const getCheckoutResult = async (checkoutId: string) => {
  const response = await ApiService.workspaceInstance.get<{ id: string; status: string }>(
    `/payments/success/?checkout_id=${checkoutId}`,
  )

  return response.data
}

export const devResetSubscription = async () => {
  await ApiService.workspaceInstance.delete('/payments/reset-subscriptions')
}
// @ts-expect-error Expose to window
window.devResetSubscription = devResetSubscription
