import { Loader, OnsaLogo } from '@onsaui'
import { useParams } from 'react-router-dom'

import useAuth from '@/modules/auth/hooks/useAuth'
import GeneralLayout from '@/modules/general/components/GeneralLayout/GeneralLayout'
import WorkspaceHeader from '@/modules/general/components/WorkspaceHeader/WorkspaceHeader'
import WorkspaceSidebar from '@/modules/general/components/WorkspaceSidebar/WorkspaceSidebar'
import TaskErrorWidget from '@/modules/task/components/TaskErrorWidget/TaskErrorWidget'
import usePublicTask from '@/modules/task/hooks/usePublicTask'

import TaskPage from '../task/TaskPage'

const PublicTaskPage: React.FC = () => {
  const { taskPublicId } = useParams()
  const { isAuth } = useAuth()

  const { data: task, isPending, isError, error } = usePublicTask(taskPublicId!)

  let body = null
  if (isPending) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center px-6">
        <Loader />
      </div>
    )
  } else if (isError) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center px-6">
        <TaskErrorWidget error={error} authRedirect={`/shared/t/${taskPublicId}`} />
      </div>
    )
  } else {
    body = isAuth ? (
      <TaskPage
        task={task}
        taskContext={{
          withAddToWorkspace: true,
          taskPublicId,
        }}
      />
    ) : (
      <TaskPage task={task} />
    )
  }

  if (isAuth) {
    return <GeneralLayout sidebar={<WorkspaceSidebar />} header={<WorkspaceHeader />} body={body} />
  }

  return <GeneralLayout header={<OnsaLogo />} body={body} noSidebar />
}

export default PublicTaskPage
