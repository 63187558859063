import { Button, Input } from '@onsaui'
import { IllustrationIcp } from '@onsaui/icons'
import cx from 'clsx'
import { useState } from 'react'

import { openSignInToContinueModal } from '@/modules/auth'
import PriceBadge from '@/modules/billing/components/PriceBadge/PriceBadge'
import { openUpgradeToProPromoModal } from '@/modules/billing/store/billingStore'
import { Icp } from '@/modules/icp/model'
import AnalyticsService from '@/services/analytics'

import useCreateIcp from '../../hooks/useCreateIcp'

const CreateIcpWidget: React.FC<{
  onIcpCreated?: (icp: Icp) => void
  className?: string
  isAnon?: boolean
  creditsCost?: number
}> = ({ onIcpCreated, className, isAnon, creditsCost }) => {
  const [personUrl, setPersonUrl] = useState('')
  const handlePersonUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonUrl(e.target.value)
  }

  const handleLimitExeededError = () => {
    if (isAnon) {
      openSignInToContinueModal()
    } else {
      openUpgradeToProPromoModal()
    }
  }

  const { isCreating, createIcp } = useCreateIcp(handleLimitExeededError)

  const handleCreate = async () => {
    AnalyticsService.trackEvent('CreateIcpWidget.BtnCreate.Click')

    const icp = await createIcp(personUrl)
    onIcpCreated?.(icp)
  }

  return (
    <section
      className={cx(
        'relative flex max-w-md flex-col items-center rounded-[40px] bg-level1 px-6 pb-6 pt-16',
        className,
      )}
    >
      {creditsCost && (
        <PriceBadge price={creditsCost} className="absolute -right-3 top-2 -translate-y-1/2" />
      )}

      <IllustrationIcp className="mb-5" />
      <h2 className="mb-2 text-center">Create ICP to Find Prospect Leads</h2>
      <p className="mb-5 text-center">
        Send me web site link to the company you would like to find leads for.
      </p>

      <div className="flex-grow" />

      <div className="flex w-full flex-col">
        <Input
          id="personUrl"
          className="mb-4 w-full"
          placeholder="Enter person linkedin profile url"
          value={personUrl}
          onChange={handlePersonUrlChange}
        />

        <Button
          className="w-full"
          isDisabled={!personUrl.trim().length || isCreating}
          isLoading={isCreating}
          onClick={handleCreate}
        >
          Send
        </Button>
      </div>
    </section>
  )
}

export default CreateIcpWidget
