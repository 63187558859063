import { UserMe } from '@/modules/user/model'
import { ApiService } from '@/services/api'

import { userMeAdapter } from './userApiAdapters'
import { UserMeResponse } from './userApiInterfaces'

export const getUserMe = async (): Promise<UserMe> => {
  const response = await ApiService.workspaceInstance.get<UserMeResponse>('/profile/me')

  return userMeAdapter(response.data)
}

export const sendCustomPlanRequest = async (name: string, email: string, comment: string) => {
  await ApiService.workspaceInstance.post('/profile/quote', { name, email, comment })
}
