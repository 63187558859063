import {
  AnyTask,
  AnyTaskResponse,
  InternalPersonInfo,
  PersonMemo,
  TaskInfo,
  TaskSignedUrl,
  TaskType,
  TranscriptReport,
} from '@/modules/task/model'
import assertUnreachable from '@/shared/helpers/assertUnreachable'

import { TaskInfoResponse, TaskSignedUrlResponse } from './taskApiInterfaces'

export const anyTaskAdapter = (taskResponse: AnyTaskResponse): AnyTask => {
  if (taskResponse.type === TaskType.PersonMemo) {
    return PersonMemo.taskConfig.taskAdapter(taskResponse)
  }
  if (taskResponse.type === TaskType.TranscriptReport) {
    return TranscriptReport.taskConfig.taskAdapter(taskResponse)
  }
  if (taskResponse.type === TaskType.InternalPersonInfo) {
    return InternalPersonInfo.taskConfig.taskAdapter(taskResponse)
  }

  return assertUnreachable(
    taskResponse,
    `Unknown task type: ${(taskResponse as unknown as { type: string }).type}`,
  )
}

export const taskInfoAdapter = (taskResponse: TaskInfoResponse): TaskInfo => {
  const taskInfo: TaskInfo = {
    id: taskResponse.id,
    type: taskResponse.type,
    title: taskResponse.title,
    status: taskResponse.status,
    errorMessage: taskResponse.errorMessage,
  }

  return taskInfo
}

export const taskSignedUrlAdapter = (response: TaskSignedUrlResponse): TaskSignedUrl => {
  const taskSignedUrl: TaskSignedUrl = {
    taskId: response.taskId,
    signedUrl: response.signedUrl,
  }

  return taskSignedUrl
}
