import { Button, notify } from '@onsaui'
import cx from 'clsx'
import { useState } from 'react'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { taskApi } from '@/modules/task/api'
import { TranscriptReport } from '@/modules/task/model'
import downloadTxt from '@/shared/helpers/downloadTxt'

const TranscriptReportView: React.FC<{
  taskId: string
  taskData: TranscriptReport.TranscriptReportTaskData
  className?: string
}> = ({ taskId, taskData, className }) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const handleDownloadTranscript = async () => {
    setIsDownloading(true)
    try {
      const transcriptText = await taskApi.getTranscript(taskId)
      downloadTxt(transcriptText, taskData.analysis.call_title)
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <div className={cx('flex flex-col gap-8', className)}>
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        <section className={cx('report-section flex flex-col gap-6')}>
          <h2 className="font-semibold">
            🔍 Call Summary{' '}
            <span className="text-base">({taskData.analysis.duration_mins} min)</span>
          </h2>
          <p>{taskData.analysis.call_summary}</p>

          <Button
            variant="outlined"
            onClick={handleDownloadTranscript}
            isDisabled={isDownloading}
            isLoading={isDownloading}
          >
            Download Call Transcript
          </Button>
        </section>
        <section className={cx('report-section flex flex-col gap-6')}>
          <h2 className="font-semibold">
            ️📊 Score: {taskData.analysis.total_score.actual}/{taskData.analysis.total_score.max}
          </h2>

          <hr className="border-dashed border-contrast" />

          <h2 className="font-semibold">🚩 Stage</h2>
          <p>{taskData.analysis.detected_stage}</p>

          <hr className="border-dashed border-contrast" />

          <h2 className="font-semibold">️🎯 Goal achieved</h2>
          <p>{taskData.analysis.goal_achieved}</p>
        </section>
      </div>

      <section className={cx('report-section-primary flex flex-col gap-6')}>
        <h2 className="font-semibold">📝 Recommendations</h2>
        <p>{taskData.analysis.advice}</p>
      </section>

      <h2 className="font-semibold">Principles</h2>

      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        {taskData.analysis.criteria.map((princip, n) => {
          return (
            <section key={princip.title} className={cx('report-section flex flex-col gap-6')}>
              <div className="flex flex-row items-center gap-3">
                <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-primary/20 text-h2">
                  {n + 1}
                </div>
                <h2 className="font-semibold">
                  {princip.title}:{' '}
                  <span
                    className={cx({
                      'text-danger': princip.score <= 3,
                      'text-[#0DDE54]': princip.score > 3,
                    })}
                  >
                    {princip.score}
                  </span>
                </h2>
              </div>
              <p>{princip.comment}</p>
            </section>
          )
        })}
      </div>

      <h2 className="font-semibold">Improve your next call</h2>

      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        {taskData.corrections.map((correction, n) => {
          return (
            <section key={n} className={cx('report-section flex flex-col gap-6')}>
              <h2 className="font-semibold">Original phrase</h2>
              <p>{correction.original_phrase}</p>

              <hr className="border-dashed border-contrast" />

              <h2 className="font-semibold">Suggestion</h2>
              <p>{correction.suggestion}</p>

              <h2 className="font-semibold">New phrase</h2>
              <p>{correction.new_phrase}</p>
            </section>
          )
        })}
      </div>
    </div>
  )
}
export default TranscriptReportView
