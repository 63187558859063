import { Button, Modal } from '@onsaui'
import { IllustrationWelcome } from '@onsaui/icons'
import { useEffect } from 'react'

import {
  closeSignInToContinueModal,
  openSignInModal,
  useAuthStore,
} from '@/modules/auth/store/authStore'
import AnalyticsService from '@/services/analytics'

const SignInToContinueModal: React.FC = () => {
  const { isSignInToContinueModalOpen } = useAuthStore()

  const handleUpgradeClick = () => {
    AnalyticsService.trackEvent('BtnSignIn.Click', { from: 'SignInToContinueModal' })

    openSignInModal()
    closeSignInToContinueModal()
  }

  useEffect(() => {
    if (isSignInToContinueModalOpen) {
      AnalyticsService.trackEvent('SignInToContinueModal.Open')
    }
  }, [isSignInToContinueModalOpen])

  return (
    <Modal
      className="max-w-sm"
      isOpen={isSignInToContinueModalOpen}
      onClose={closeSignInToContinueModal}
    >
      <IllustrationWelcome className="m-auto mb-5" />
      <h2 className="mb-5 text-center">Sign In to continue</h2>
      <p className="mb-5 text-center">
        It looks like you&apos;ve reached the limit of your free tries. To access this feature and
        continue enjoying our services, you&apos;ll need to Sign In.
      </p>

      <Button className="mb-5 w-full" onClick={handleUpgradeClick}>
        Sign In
      </Button>
    </Modal>
  )
}
export default SignInToContinueModal
