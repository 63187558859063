import React from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'

import SignInForm from '@/modules/auth/components/SignInForm/SignInForm'
import useAuth from '@/modules/auth/hooks/useAuth'

const SignInPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect') || '/'

  const navigate = useNavigate()
  const handleSuccess = () => {
    navigate(redirect, { replace: true })
  }

  const { isAuth } = useAuth()
  if (isAuth) {
    return <Navigate to="/" replace />
  }

  return (
    <main className="flex h-full w-full flex-col items-center justify-center bg-level2">
      <div className="w-full max-w-[380px] rounded-[30px] bg-level1 p-8">
        <SignInForm onSignInSuccess={handleSuccess} redirect={redirect} />
      </div>
    </main>
  )
}

export default SignInPage
