import { useQuery } from '@tanstack/react-query'

import useAuth from '@/modules/auth/hooks/useAuth'
import { workspaceApi } from '@/modules/workspace/api'

const useWorkspaces = () => {
  const { isAuth } = useAuth()

  return useQuery({
    queryKey: ['workspaces'],
    queryFn: () => {
      return workspaceApi.getWorkspaces()
    },
    enabled: isAuth,
  })
}

export default useWorkspaces
