window.dataLayer = window.dataLayer || []
function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments)
}

// const GTAG_ID = import.meta.env.VITE_GTAG_ID as string

const AnalyticsService = {
  trackEvent: (eventName: string, data?: Record<string, unknown>) => {
    try {
      // @ts-expect-error - Use gtag
      gtag('event', eventName, data)
    } catch (e) {
      console.error('AnalyticsService.trackEvent', e)
    }
  },
  setUserId: (userId: string | undefined) => {
    try {
      // @ts-expect-error - Use gtag
      gtag('set', {
        user_id: userId,
      })
    } catch (e) {
      console.error('AnalyticsService.setUserId', e)
    }
  },
}

export default AnalyticsService
