/* eslint-disable react/no-children-prop */
import { Button, notify, TextArea } from '@onsaui'
import cx from 'clsx'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { icpActions } from '@/modules/icp'
import { Icp } from '@/modules/icp/model'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

const IcpPageBody: React.FC<{ icp: Icp; className?: string }> = ({ icp, className }) => {
  const [context, setContext] = useState(icp.context)
  const handleContextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContext(e.target.value)
  }

  const [isUpdating, setIsUpdating] = useState(false)
  const handleUpdateContext = async (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!context) {
      return
    }
    if (context.length < 10) {
      notify({ message: 'Context should be at least 10 characters long', variant: 'error' })
      return
    }

    setIsUpdating(true)
    try {
      const updatedIcp = await icpActions.updateIcp(icp.id, { context })
      setContext(updatedIcp.context)

      notify({ message: 'Saved', variant: 'success' })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsUpdating(false)
    }
  }

  const handleRegenerate = async () => {
    try {
      await icpActions.regenerateIcp(icp.id)
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    }
  }

  return (
    <div className={cx('', className)}>
      {/* <div className={cx('flex flex-row items-center gap-4')}>
        <h1 className="mobile:text-h2">{icp.title}</h1>
      </div> */}

      <div className="rounded-3xl border border-default p-10">
        <ReactMarkdown className="" children={icp.body} />
      </div>

      <div className="mt-4">
        <div className="mb-2 flex flex-row items-center justify-between">
          <h2>Add more context</h2>

          <Button variant="outlined" size="medium" onClick={handleRegenerate}>
            Regenerate with context
          </Button>
        </div>
        <TextArea
          value={context}
          onChange={handleContextChange}
          placeholder="Any additional info to make ICP more accurate"
          onBlur={handleUpdateContext}
        />
      </div>
    </div>
  )
}

export default IcpPageBody
