import { Button, Modal, notify } from '@onsaui'
import { IllustrationRegenerate } from '@onsaui/icons'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { closeRegenerateTaskModal, useTaskStore } from '@/modules/task'
import { taskActions } from '@/modules/task/actions'

const RegenerateTaskModal: React.FC = () => {
  const { taskRegenerateModal } = useTaskStore()
  const { isOpen, taskId } = taskRegenerateModal
  const { workspaceId } = useParams()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    closeRegenerateTaskModal()
  }

  const handleRegenerate = async () => {
    setIsLoading(true)
    try {
      const task = await taskActions.regenerateTask(taskId)

      navigate(`/${workspaceId}/t/${task.id}`)

      handleClose()
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal className="w-full max-w-sm" isOpen={isOpen} onClose={handleClose}>
      <IllustrationRegenerate className="m-auto mb-5" />
      <h2 className="mb-3 text-center">Would you like to regenerate&nbsp;this task?</h2>

      <Button
        className="mt-3 w-full"
        onClick={handleRegenerate}
        isDisabled={isLoading}
        isLoading={isLoading}
      >
        Regenerate
      </Button>
      <Button className="w-full" variant="ghost" onClick={handleClose}>
        Cancel
      </Button>
    </Modal>
  )
}
export default RegenerateTaskModal
