import { ApiService } from '@/services/api'

export const sendAuthEmail = async (
  email: string,
  timezone: string,
  redirect?: string,
): Promise<void> => {
  await ApiService.apiInstance.post('auth/login', {
    email,
    timezone,
    redirect,
  })
}

export const authViaCode = async (code: string, timezone: string) => {
  const response = await ApiService.apiInstance.post<{
    accessToken: string
    userId: string
  }>('auth/code', { code, timezone })

  return response.data
}
