export const validateTxtFile = (file: File): Promise<{ valid: boolean; message?: string }> => {
  return new Promise((resolve, reject) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase()
    if (fileExtension !== 'txt') {
      resolve({ valid: false, message: 'File extension is not .txt' })
    }

    if (file.type !== 'text/plain') {
      resolve({ valid: false, message: 'File MIME-type is not text/plain' })
    }

    const reader = new FileReader()

    reader.onload = (event) => {
      try {
        const arrayBuffer = event.target?.result as ArrayBuffer

        // Convert ArrayBuffer to Uint8Array to work with raw bytes
        const uint8Array = new Uint8Array(arrayBuffer)

        // Check if the file has a UTF-16 BOM (0xFFFE or 0xFEFF)
        if (hasUtf16BOM(uint8Array)) {
          return resolve({
            valid: false,
            message: 'File content is not UTF-8 encoded',
          })
        }

        // Convert ArrayBuffer to string for UTF-8 validation
        const text = new TextDecoder('utf-8').decode(arrayBuffer)

        if (text) {
          // Check if the content contains the replacement character
          if (!isValidUTF8(text)) {
            return resolve({
              valid: false,
              message:
                'File contains invalid UTF-8 characters. Ensure the file is saved as UTF-8 encoded',
            })
          }

          resolve({ valid: true })
        } else {
          resolve({ valid: false, message: 'File content is not UTF-8 encoded' })
        }
      } catch (error) {
        resolve({ valid: false, message: 'Error reading the file' })
      }
    }

    reader.onerror = () => {
      resolve({ valid: false, message: 'Error reading the file' })
    }

    // Read the file as ArrayBuffer to inspect raw bytes
    reader.readAsArrayBuffer(file)
  })
}

const hasUtf16BOM = (bytes: Uint8Array): boolean => {
  // UTF-16 BOM detection (0xFFFE or 0xFEFF)
  if ((bytes[0] === 0xff && bytes[1] === 0xfe) || (bytes[0] === 0xfe && bytes[1] === 0xff)) {
    return true
  }
  return false
}

// Function to check for invalid UTF-8 characters by detecting the replacement character (�)
const isValidUTF8 = (content: string): boolean => {
  for (let i = 0; i < content.length; i++) {
    if (content[i] === '�') {
      return false
    }
  }
  return true
}
