import { Button } from '@onsaui'
import { IconMagicWand } from '@onsaui/icons'
import cx from 'clsx'
import { useEffect, useState } from 'react'

import { openSignInModal } from '@/modules/auth'
import useAuth from '@/modules/auth/hooks/useAuth'
import { openSystemPromptSettingsModal } from '@/modules/workspace/store/workspaceStore'
import AnalyticsService from '@/services/analytics'
import useWorkspace from '../../../workspace/hooks/useWorkspace'

const PersonalizeReportBanner: React.FC<{ workspaceId: string; className?: string }> = ({
  workspaceId,
  className,
}) => {
  const { isAuth } = useAuth()
  const [isVisible, setIsVisible] = useState(false)

  const { data: workspace } = useWorkspace(workspaceId)

  useEffect(() => {
    if (workspace) {
      // Hide banner if personalInfo or companyInfo is non-empty
      if (workspace.personalInfo || workspace.companyInfo) {
        setIsVisible(false)
        localStorage.setItem('hidePersonalizeReportBanner', 'true')
      } else {
        setIsVisible(!localStorage.getItem('hidePersonalizeReportBanner'))
      }
    }
  }, [workspace])

  const handleClose = () => {
    AnalyticsService.trackEvent('PersonalizeReportBanner.BtnClose.Click')

    setIsVisible(false)
    localStorage.setItem('hidePersonalizeReportBanner', 'true')
  }

  const handlePersonalizeClick = () => {
    AnalyticsService.trackEvent('BtnCustomizePrompt.Click', { from: 'PersonalizeReportBanner' })

    openSystemPromptSettingsModal()
  }
  const handleSignInClick = () => {
    AnalyticsService.trackEvent('BtnSignIn.Click', { from: 'PersonalizeReportBanner' })

    openSignInModal()
  }

  useEffect(() => {
    if (isVisible) {
      AnalyticsService.trackEvent('PersonalizeReportBanner.Show')
    }
  }, [isVisible])

  if (!isVisible) {
    return null
  }

  return (
    <div
      className={cx(
        'report-section flex flex-row flex-wrap items-center gap-4 !p-4 lg:flex-nowrap lg:gap-0',
        className,
      )}
    >
      <div className="flex h-[60px] w-[60px] shrink-0 flex-col items-center justify-center rounded-2xl bg-[#D8E5FF]">
        <IconMagicWand />
      </div>

      <p className="flex-grow lg:ml-5 lg:mr-8">
        {isAuth
          ? 'To create this report, we used basic information. For a more customized and relevant report, please add your personal details.'
          : 'To create this report, we used basic information. For a more relevant report, please Sign In and add your personal details.'}
      </p>

      <div className="inline-flex">
        <Button className="mr-3" variant="outlined" onClick={handleClose}>
          Close
        </Button>
        {isAuth ? (
          <Button className="" onClick={handlePersonalizeClick}>
            Personalize
          </Button>
        ) : (
          <Button className="" onClick={handleSignInClick}>
            Sign In
          </Button>
        )}
      </div>
    </div>
  )
}
export default PersonalizeReportBanner
