import { useEffect, useRef } from 'react'

import { taskActions } from '@/modules/task/actions'

import useTask from './useTask'

const useTaskPolling = (taskId: string) => {
  const { data: task } = useTask(taskId)

  const taskStatusRef = useRef(task?.status)

  useEffect(() => {
    if (!task) {
      return
    }

    if (
      taskStatusRef.current &&
      taskStatusRef.current !== 'completed' &&
      task.status === 'completed'
    ) {
      taskActions.invalidateTasks()
    }
    taskStatusRef.current = task.status

    if (task.status !== 'completed' && task.status !== 'failed') {
      const interval = setInterval(() => {
        taskActions.invalidateTask(taskId)
      }, 2000)
      return () => clearInterval(interval)
    }
  }, [task, taskId])
}
export default useTaskPolling
