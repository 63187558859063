import { useEffect } from 'react'

import { taskActions } from '@/modules/task/actions'

import useAnonTask from './useAnonTask'

const useAnonTaskPoling = (taskId: string) => {
  const { data: task } = useAnonTask(taskId)

  useEffect(() => {
    if (!task) {
      return
    }

    if (task.status !== 'completed' && task.status !== 'failed') {
      const interval = setInterval(() => {
        taskActions.invalidateTask(taskId)
      }, 2000)
      return () => clearInterval(interval)
    }
  }, [task, taskId])
}
export default useAnonTaskPoling
