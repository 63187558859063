import { notify } from '@onsaui'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { taskActions } from '@/modules/task/actions'

const useAddToWorkspaceTask = () => {
  const { workspaceId } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const addToWorkspace = async (taskPublicId: string) => {
    setIsLoading(true)
    try {
      const task = await taskActions.addTaskToWorkspace(taskPublicId)

      notify({ message: 'Task added to your workspace', variant: 'success' })

      setTimeout(() => {
        navigate(`/${workspaceId}/t/${task.id}`)
      }, 500)
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, addToWorkspace }
}

export default useAddToWorkspaceTask
