import { useQuery } from '@tanstack/react-query'

import { taskApi } from '@/modules/task/api'

const useAnonTask = (taskId: string) => {
  return useQuery({
    queryKey: ['anon-task', taskId],
    queryFn: () => {
      return taskApi.getAnonTask(taskId)
    },
  })
}

export default useAnonTask
