import { Loader, OnsaLogo } from '@onsaui'
import { useParams } from 'react-router-dom'

import AnonSidebar from '@/modules/general/components/AnonSidebar/AnonSidebar'
import GeneralLayout from '@/modules/general/components/GeneralLayout/GeneralLayout'
import TaskErrorWidget from '@/modules/task/components/TaskErrorWidget/TaskErrorWidget'
import useAnonTask from '@/modules/task/hooks/useAnonTask'
import useAnonTaskPoling from '@/modules/task/hooks/useAnonTaskPoling'

import TaskPage from '../task/TaskPage'

const PlaygroundTaskPage: React.FC = () => {
  const { taskId } = useParams()
  const { data: task, isPending, isError, error } = useAnonTask(taskId!)

  useAnonTaskPoling(taskId!)

  let body = null
  if (isPending) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center px-6">
        <Loader />
      </div>
    )
  } else if (isError) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center px-6">
        <TaskErrorWidget error={error} />
      </div>
    )
  } else {
    body = <TaskPage task={task} taskContext={{ withFeedback: true }} />
  }

  return <GeneralLayout sidebar={<AnonSidebar />} header={<OnsaLogo />} body={body} />
}

export default PlaygroundTaskPage
