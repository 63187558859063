import { outreachTemplateApi } from '@/modules/icp/api'
import { OutreachTemplate } from '@/modules/icp/model'
import { QueryService } from '@/services/query'

export const createOutreachTemplate = async (
  icpId: string,
  title: string,
  message: string,
  position: number = 100,
) => {
  const template = await outreachTemplateApi.createOutreachTemplate(icpId, title, message, position)

  QueryService.getClient().setQueriesData(
    {
      queryKey: ['outreachTemplates', icpId],
    },
    (oldData?: OutreachTemplate[]) => {
      if (!oldData) {
        return oldData
      }

      return [...oldData, template]
    },
  )

  return template
}

export const deleteOutreachTemplate = async (icpId: string, templateId: string) => {
  await outreachTemplateApi.deleteOutreachTemplate(icpId, templateId)

  QueryService.getClient().setQueriesData(
    {
      queryKey: ['outreachTemplates', icpId],
    },
    (oldData?: OutreachTemplate[]) => {
      if (!oldData) {
        return oldData
      }

      return oldData.filter((template) => template.id !== templateId)
    },
  )
}
