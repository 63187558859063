import { Modal } from '@onsaui'
import { useEffect } from 'react'

import { closeUpgradeToProModal, useBillingStore } from '@/modules/billing/store/billingStore'
import AnalyticsService from '@/services/analytics'

import UpgradeToProContent from './UpgradeToProContent'

const UpgradeToProModal: React.FC = () => {
  const { isUpgradeToProModalOpen } = useBillingStore()

  useEffect(() => {
    if (isUpgradeToProModalOpen) {
      AnalyticsService.trackEvent('UpgradeToProModal.Open')
    }
  }, [isUpgradeToProModalOpen])

  return (
    <Modal
      className="w-full max-w-md"
      isOpen={isUpgradeToProModalOpen}
      onClose={closeUpgradeToProModal}
    >
      <UpgradeToProContent />
    </Modal>
  )
}
export default UpgradeToProModal
