import { notify } from '@onsaui'
import { IconPlusSm } from '@onsaui/icons'
import cx from 'clsx'
import { useRef, useState } from 'react'

import { validateTxtFile } from './validate'

const allowedFileTypes = ['.mp3', '.m4a', '.webm', '.wav', '.ogg', '.mp4', '.txt']
const MAX_SIZE = 170 * 1024 * 1024 // 10MB

const FileDropArea: React.FC<{
  file?: File
  onFileSelected: (file: File | undefined) => void
  className?: string
}> = ({ file, onFileSelected, className }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const openFilePicker = () => {
    fileInputRef.current?.click()
  }

  const validateFile = async (file: File) => {
    if (file.size > MAX_SIZE) {
      notify({ message: 'File is too large, max size is 10MB', variant: 'error' })
      return false
    }
    if (!allowedFileTypes.some((ext) => file.name.endsWith(ext))) {
      notify({ message: 'Unsupported file extension', variant: 'error' })
      return false
    }

    if (file.name.endsWith('.txt') || file.type === 'text/plain') {
      const { valid, message } = await validateTxtFile(file)
      if (!valid) {
        notify({ message: message ?? 'Error processing the file', variant: 'error' })
        return false
      }
    }

    return true
  }

  const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (!file || !(await validateFile(file))) {
      e.target.value = ''
      return
    }

    onFileSelected(file)
  }

  const [isDragOver, setIsDragOver] = useState(false)
  const handleDrop = async (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsDragOver(false)

    const file = e.dataTransfer.files?.[0]
    if (!file || !(await validateFile(file))) {
      return
    }

    onFileSelected(file)
  }

  return (
    <>
      <input
        ref={fileInputRef}
        id="input"
        type="file"
        className="hidden"
        accept={allowedFileTypes.join(', ')}
        onChange={handleFileSelected}
      />

      <button
        type="button"
        className={cx(
          'flex flex-col items-center justify-center rounded-2xl border-2 border-dashed border-disabled p-4 transition-colors hover:border-primary',
          { 'border-primary bg-primary/20': isDragOver },
          className,
        )}
        onClick={openFilePicker}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={(e) => {
          setIsDragOver(true)
        }}
        onDragLeave={(e) => {
          setIsDragOver(false)
        }}
      >
        <div className="pointer-events-none flex flex-row items-center gap-2">
          <p>Drag and Drop file or click to select</p>
          <IconPlusSm />
        </div>
      </button>
    </>
  )
}

export default FileDropArea
