import { ApiService } from '@/services/api'

import { outreachTemplateAdapter } from './outreachTemplateApiAdapters'
import { OutreachTemplateResponse } from './outreachTemplateApiInterfaces'

export const getOutreachTemplates = async (icpId: string) => {
  const response = await ApiService.workspaceInstance.get<OutreachTemplateResponse[]>(
    `/icps/${icpId}/outreach-templates`,
  )

  return response.data.map((template) => outreachTemplateAdapter(template))
}

export const createOutreachTemplate = async (
  icpId: string,
  title: string,
  message: string,
  position: number = 100,
) => {
  const response = await ApiService.workspaceInstance.post<OutreachTemplateResponse>(
    `/icps/${icpId}/outreach-templates/create`,
    { title, message, position },
  )

  return outreachTemplateAdapter(response.data)
}

export const updateOutreachTemplate = async (
  icpId: string,
  templateId: string,
  changes: Partial<OutreachTemplateResponse>,
) => {
  const response = await ApiService.workspaceInstance.patch<OutreachTemplateResponse>(
    `/icps/${icpId}/outreach-templates/${templateId}`,
    changes,
  )

  return outreachTemplateAdapter(response.data)
}

export const deleteOutreachTemplate = async (icpId: string, templateId: string) => {
  await ApiService.workspaceInstance.delete(`/icps/${icpId}/outreach-templates/${templateId}`)
}
