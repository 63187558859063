import { TooltipContentProps } from '@radix-ui/react-tooltip'
import cx from 'clsx'
import React from 'react'

import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip/Tooltip'

const InfoTooltip: React.FC<{
  children?: React.ReactNode
  content: React.ReactNode
  className?: string
  align?: TooltipContentProps['align']
  side?: TooltipContentProps['side']
}> = ({ children, align = 'center', side = 'bottom', content, className }) => {
  return (
    <Tooltip delayDuration={200}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>

      <TooltipContent
        // className={cx('z-20 rounded-2xl border border-default bg-level1 shadow-md', className)}
        sideOffset={8}
        align={align}
        side={side}
        className="z-20"
        // asChild
      >
        {content}
      </TooltipContent>
    </Tooltip>
  )
}

export default InfoTooltip
