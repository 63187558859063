import { TextArea } from '@onsaui'
import cx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import useOutreachTemplateActions from '@/modules/icp/hooks/useOutreachTemplateActions'

const OutreachTemplateDraft: React.FC<{
  icpId: string
  onCancel: () => void
  className?: string
}> = ({ icpId, onCancel, className }) => {
  const { createOutreachTemplate, isCreating } = useOutreachTemplateActions()

  const [message, setMessage] = useState('')
  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const handleBlur = async () => {
    if (!message.trim()) {
      onCancel()
    } else {
      await createOutreachTemplate(icpId, 'ICP Offer', message, 10)
      onCancel()
    }
  }

  return (
    <div className={cx('rounded-3xl border border-default p-5', className)} ref={ref}>
      <h3 className="mb-2 font-semibold">Offering text</h3>
      <TextArea
        className="w-full"
        inputClassName="!rounded-2xl"
        placeholder="Type your message here..."
        autoHeight={{ enabled: true, minLines: 3, maxLines: 10 }}
        onChange={handleMessageChange}
        value={message}
        onBlur={handleBlur}
        isDisabled={isCreating}
        autoFocus
      />
    </div>
  )
}

export default OutreachTemplateDraft
