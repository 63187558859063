import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'

const MODE = import.meta.env.MODE as string
const BASE_URL = import.meta.env.VITE_API_BASE_URL as string

type SentryCaptureExeptionParams = Parameters<typeof Sentry.captureException>

export const ErrorService = {
  init: () => {
    Sentry.init({
      enabled: MODE !== 'dev',
      environment: MODE,

      dsn: 'https://5220e225ec979bdc533a6ad929c32d12@o4506355201540096.ingest.us.sentry.io/4507543709220864',

      integrations: [Sentry.browserTracingIntegration()],

      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/.*.ngrok-free.app\/?.*/,
        new RegExp(BASE_URL),
      ],

      // beforeSend: (event) => {
      //   console.log('Sentry beforeSend', event);

      //   return event;
      // },

      // Session Replay
      // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  },

  captureException: (e: SentryCaptureExeptionParams[0], d?: SentryCaptureExeptionParams[1]) => {
    Sentry.captureException(e, d)

    if (MODE === 'dev') {
      console.error(e)
    }
  },

  captureAxiosError: (error: AxiosError) => {
    const fingerprint: string[] = []
    let invalidAuthToken = false

    if (error.response?.status === 401) {
      invalidAuthToken = true
      fingerprint.push('invalid-auth-token')
    }

    if (!error.status && error.message === 'Network Error') {
      fingerprint.push('network-error')
    }

    ErrorService.captureException(error, {
      tags: { apiError: true, ...(invalidAuthToken ? { invalidAuthToken } : {}) },
      extra: { apiRequestUrl: `${error.config?.baseURL}/${error.config?.url}` },
      fingerprint: fingerprint.length ? fingerprint : undefined,
    })
  },
}
