import { Loader } from '@onsaui'
import { useParams } from 'react-router-dom'

import GeneralLayout from '@/modules/general/components/GeneralLayout/GeneralLayout'
import WorkspaceHeader from '@/modules/general/components/WorkspaceHeader/WorkspaceHeader'
import WorkspaceSidebar from '@/modules/general/components/WorkspaceSidebar/WorkspaceSidebar'
import useIcp from '@/modules/icp/hooks/useIcp'
import useIcpPoling from '@/modules/icp/hooks/useIcpPoling'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { TaskStatus } from '@/modules/task/model'

import IcpPageBody from './IcpPageBody'

const IcpPage: React.FC = () => {
  const { icpId } = useParams()
  const { data: icp, isPending, isError, error } = useIcp(icpId!)

  useIcpPoling(icpId!)

  let body = null
  if (isPending) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center px-6">
        <Loader />
      </div>
    )
  } else if (isError) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center px-6">
        <p className="text-danger">{getErrorMessage(error)}</p>
      </div>
    )
  } else if (icp.status === TaskStatus.Completed) {
    body = <IcpPageBody icp={icp} className="mx-auto w-full max-w-5xl px-6 pb-8" />
  } else if (icp.status === TaskStatus.Failed) {
    body = <p className="text-danger">Unable to load ICP...</p>
  } else {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center px-6">
        <h2 className="mb-1">We are generating ICP for you</h2>
        <p className="mb-4">It may take a few minutes...</p>

        <Loader />
      </div>
    )
  }

  return <GeneralLayout sidebar={<WorkspaceSidebar />} header={<WorkspaceHeader />} body={body} />
}

export default IcpPage
