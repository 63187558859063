import { useCallback, useEffect, useRef, useState } from 'react'

import cm from './styles.module.css'

export const useHighlightAnim = () => {
  const [highlightClass, setHighlightClass] = useState('')

  const mountedRef = useRef(true)
  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const timeoutRef = useRef<number>(-1)

  const highlightIt = useCallback(() => {
    setHighlightClass('')

    window.clearTimeout(timeoutRef.current)
    timeoutRef.current = window.setTimeout(() => {
      if (!mountedRef.current) {
        return
      }

      setHighlightClass(cm.highlight)
      timeoutRef.current = window.setTimeout(() => {
        if (!mountedRef.current) {
          return
        }

        setHighlightClass('')
      }, 500)
    }, 0)
  }, [])

  return [highlightClass, highlightIt] as const
}
