import cx from 'clsx'

const EmojiAvatar: React.FC<{ emoji?: string; className?: string }> = ({
  emoji = '🤠',
  className,
}) => {
  return (
    <div className={cx('flex flex-col items-center justify-center bg-[#ACD4F2]', className)}>
      <p>{emoji}</p>
    </div>
  )
}
export default EmojiAvatar
