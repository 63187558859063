import { Button, Loader, Modal } from '@onsaui'
import { IllustrationFun, IllustrationWarning } from '@onsaui/icons'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'

import { billingApi } from '@/modules/billing/api'
import { SUPPORT_EMAIL } from '@/shared/constants/supportEmail'

const PaymentSuccessModal: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const paymentSuccess = searchParams.get('payment_success')
  const checkoutId = searchParams.get('checkout_id')

  const isOpen = paymentSuccess === 'true' && Boolean(checkoutId)

  const handleCloseModal = () => {
    const updatedSearchParams = new URLSearchParams(searchParams)
    updatedSearchParams.delete('payment_success')
    updatedSearchParams.delete('checkout_id')
    setSearchParams(updatedSearchParams)
  }

  const {
    data: checkout,
    isPending,
    isError,
  } = useQuery({
    queryKey: ['checkout', checkoutId],
    queryFn: () => {
      return billingApi.getCheckoutResult(checkoutId!)
    },
    enabled: isOpen,
  })

  let body = null
  if (isPending) {
    body = (
      <div className="flex min-h-[340px] w-[300px] flex-col items-center justify-center">
        <Loader />
      </div>
    )
  } else if (isError) {
    body = (
      <>
        <IllustrationWarning className="m-auto mb-5" />
        <h2 className="mb-5 text-center">Unable to confirm your subscription</h2>
        <p className="mb-5 text-center">Try reload the page or contact our support team</p>

        <Button className="w-full" asChild>
          <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noreferrer">
            Contact support
          </a>
        </Button>
      </>
    )
  } else {
    body = (
      <>
        <IllustrationFun className="m-auto mb-5" />
        <h2 className="mb-5 text-center">Subscription Activated!</h2>
        <p className="mb-5 text-center">
          You’ve successfully subscribed! Enjoy your new access to premium features, enhanced
          content, and priority support. We’re excited to have you on board.
        </p>

        <Button className="w-full" onClick={handleCloseModal}>
          Start Exploring
        </Button>
      </>
    )
  }

  return (
    <Modal className="max-w-sm" isOpen={isOpen} onClose={handleCloseModal}>
      {body}
    </Modal>
  )
}
export default PaymentSuccessModal
