import { Button, Loader } from '@onsaui'
import { IconInfo, IconLink } from '@onsaui/icons'
import cx from 'clsx'

import copyToClipboardAndNotify from '@/modules/shared/helpers/copyToClipboardAndNotify'
import useTask from '@/modules/task/hooks/useTask'
import { TaskAccessType, taskHelpers } from '@/modules/task/model'

import AccessDropdown from './AccessDropdown'
import PeopleForm from './PeopleForm'

const TaskSharingBody: React.FC<{ taskId: string; className?: string }> = ({
  taskId,
  className,
}) => {
  const { data: task, isPending, isError } = useTask(taskId)

  if (isPending) {
    return (
      <div className={cx('flex min-h-[300px] flex-col items-center justify-center', className)}>
        <Loader />
      </div>
    )
  }
  if (isError) {
    return (
      <div className={cx('flex min-h-[300px] flex-col items-center justify-center', className)}>
        <p className="text-danger">Failed to get task</p>
      </div>
    )
  }

  const { access } = task
  if (!access) {
    return null
  }

  const handleCopyLink = () => {
    copyToClipboardAndNotify(access.shareLink!)
  }

  return (
    <>
      <h2>Share &ldquo;{taskHelpers.getTaskTitle(task)}&rdquo;</h2>
      <AccessDropdown taskId={taskId} access={access} />

      {access.accessType === TaskAccessType.Invite ? (
        <PeopleForm taskId={taskId} access={access} />
      ) : null}

      {access.shareLink && access.accessType !== TaskAccessType.Private ? (
        <Button variant="outlined" className="w-full" onClick={handleCopyLink}>
          <IconLink className="mr-2" />
          <span>Copy Link</span>
        </Button>
      ) : (
        <div className="flex flex-row gap-2 rounded-2xl bg-disabled-surface p-5 text-secondary">
          <IconInfo className="mt-1 h-4 w-4 shrink-0" />
          <div>
            <p className="mb-1 font-semibold">Sharing is disabled</p>
            <p className="text-sm">
              Select &lsquo;Public&rsquo; or &lsquo;Restricted&rsquo; to generate a link and share
              files with others
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default TaskSharingBody
