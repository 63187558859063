import { Button, Loader } from '@onsaui'
import { IconArrowRightSm, IconCheck } from '@onsaui/icons'

import useBillingPlans from '@/modules/billing/hooks/useBillingPlans'
import useUpgradeToPlan from '@/modules/billing/hooks/useUpgradeToPlan'
import { BillingPlanTier } from '@/modules/billing/model'
import { closeUpgradeToProModal, openContactSalesModal } from '@/modules/billing/store/billingStore'
import AnalyticsService from '@/services/analytics'

const UpgradeToProContent: React.FC = () => {
  const { data: plans, isPending, isError, error } = useBillingPlans()
  const proPlan = plans?.find((plan) => plan.tier === BillingPlanTier.PRO)

  const { processingPlanId, upgradeToPlan } = useUpgradeToPlan()
  const isLoading = processingPlanId === proPlan?.id

  const handleUpgradeClick = async () => {
    AnalyticsService.trackEvent('UpgradeToProModal.BtnUpgradeToProPlan.Click')

    await upgradeToPlan(proPlan!, false, 1)

    closeUpgradeToProModal()
  }

  const handleContactSalesClick = () => {
    AnalyticsService.trackEvent('UpgradeToProModal.BtnContactSales.Click')

    openContactSalesModal()
  }

  if (isPending) {
    return (
      <div className="flex min-h-[600px] w-full flex-col items-center justify-center">
        <Loader />
      </div>
    )
  }
  if (isError) {
    return (
      <div className="flex min-h-[600px] w-full flex-col items-center justify-center">
        <p className="text-danger">{error?.message || 'Unable to get plans...'}</p>
      </div>
    )
  }
  if (!proPlan) {
    return (
      <div className="flex min-h-[600px] w-full flex-col items-center justify-center">
        <p className="text-danger">Unable to get PRO plan...</p>
      </div>
    )
  }

  return (
    <>
      <h2 className="mb-5 text-center">
        {!proPlan.isCurrent ? 'Upgrade to Professional' : 'You are on Professional'}
      </h2>
      <p className="mb-5 text-center text-2xl font-bold text-primary">
        {proPlan.currency === 'usd' ? '$' : `${proPlan.currency} `}
        {proPlan.monthlyPrice}/month
      </p>

      <p className="mb-5 text-center">
        To access this feature and continue enjoying our services, you&apos;ll need to upgrade your
        plan.
      </p>

      <div className="mb-5 flex flex-col gap-2">
        {proPlan.details.map((detail, n) => {
          return (
            <div
              key={n}
              className="flex flex-row items-center gap-2 rounded-2xl bg-disabled-surface px-5 py-4"
            >
              <IconCheck className="text-primary" />
              <span className="text-sm">{detail.content}</span>
            </div>
          )
        })}
      </div>

      {!proPlan.isCurrent ? (
        <Button
          className="mb-2 w-full"
          onClick={handleUpgradeClick}
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          Upgrade to Professional <IconArrowRightSm className="ml-2" />
        </Button>
      ) : (
        <Button className="w-full" isDisabled>
          Plan Active
        </Button>
      )}
      <Button className="w-full" variant="ghost" onClick={handleContactSalesClick}>
        Contact Sales for Enterprise
      </Button>
    </>
  )
}

export default UpgradeToProContent
