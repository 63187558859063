import { notify } from '@onsaui'

import useAuth from '@/modules/auth/hooks/useAuth'
import { taskApi } from '@/modules/task/api'
import { TaskType } from '@/modules/task/model'
import AnalyticsService from '@/services/analytics'

interface Rating {
  value: number
  emoji: string
}

const Ratings: Rating[] = [
  {
    value: 1,
    emoji: '🤬',
  },
  {
    value: 2,
    emoji: '😶',
  },
  {
    value: 3,
    emoji: '🙂',
  },
  {
    value: 4,
    emoji: '🤓',
  },
  {
    value: 5,
    emoji: '🤩',
  },
]

const TaskFeedback: React.FC<{ taskId: string; taskType: TaskType; className?: string }> = ({
  taskId,
  taskType,
  className,
}) => {
  const { isAuth } = useAuth()
  const handleRate = async (rating: Rating) => {
    AnalyticsService.trackEvent('TaskRate.Rate', { taskId, taskType, rating: rating.value })

    if (isAuth) {
      try {
        await taskApi.sendTaskFeedback(taskId, rating.value)
      } catch (e) {
        notify({ message: 'Failed to send feedback', variant: 'error' })
        throw e
      }
    }

    notify({ message: 'Thank you for your feedback!', variant: 'success' })
  }

  return (
    <div className={className}>
      <h3 className="mb-6 text-center text-base font-normal">
        Let us know if it were useful or not
      </h3>
      <div className="flex flex-row items-center justify-center gap-4">
        {Ratings.map((rating) => {
          return (
            <button
              key={rating.value}
              type="button"
              className="h-12 w-12 rounded-full p-2 text-3xl transition-all hover:scale-125"
              onClick={() => handleRate(rating)}
            >
              {rating.emoji}
            </button>
          )
        })}
      </div>
    </div>
  )
}
export default TaskFeedback
