import { useQuery } from '@tanstack/react-query'

import useAuth from '@/modules/auth/hooks/useAuth'
import { taskApi } from '@/modules/task/api'

const useTask = (taskId: string) => {
  const { isAuth } = useAuth()

  return useQuery({
    queryKey: ['task', taskId],
    queryFn: () => {
      return taskApi.getTask(taskId)
    },
    enabled: isAuth,
  })
}

export default useTask
