import { Button, Modal } from '@onsaui'
import { IllustrationWarning } from '@onsaui/icons'
import { useSearchParams } from 'react-router-dom'

import { SUPPORT_EMAIL } from '@/shared/constants/supportEmail'

const PaymentFailedModal: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const paymentSuccess = searchParams.get('payment_success')

  const isOpen = paymentSuccess === 'false'

  const handleCloseModal = () => {
    const updatedSearchParams = new URLSearchParams(searchParams)
    updatedSearchParams.delete('payment_success')
    setSearchParams(updatedSearchParams)
  }

  return (
    <Modal className="max-w-sm" isOpen={isOpen} onClose={handleCloseModal}>
      <IllustrationWarning className="m-auto mb-5" />
      <h2 className="mb-5 text-center">Unable to process your subscription!</h2>
      <p className="mb-5 text-center">
        Please check your payment details and contact our support team if you need any assistance.
      </p>

      <Button className="w-full" asChild>
        <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noreferrer">
          Contact support
        </a>
      </Button>
    </Modal>
  )
}
export default PaymentFailedModal
