import { Button, useIsDesktopScreen } from '@onsaui'
import {
  IconCopy,
  IconEdit,
  IconExternal,
  IconMenuDots,
  IconPlusSm,
  IconRetry,
} from '@onsaui/icons'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import cx from 'clsx'

import copyToClipboardAndNotify from '@/modules/shared/helpers/copyToClipboardAndNotify'
import { openRegenerateTaskModal, openTaskSharingModal } from '@/modules/task'
import { AnyTask, taskHelpers } from '@/modules/task/model'
import GlobalEventService from '@/services/globalEvent'

import useAddToWorkspaceTask from '../../hooks/useAddToWorkspaceTask'

type ToolType = 'rename' | 'copy' | 'share' | 'regenerate' | 'addToWorkspace'
const ToolsConfig: Record<ToolType, { icon: React.ReactNode; label: string }> = {
  rename: { icon: <IconEdit />, label: 'Rename' },
  copy: { icon: <IconCopy />, label: 'Copy text' },
  share: { icon: <IconExternal />, label: 'Share this task' },
  regenerate: { icon: <IconRetry />, label: 'Regenerate this task' },
  addToWorkspace: { icon: <IconPlusSm />, label: 'Add to my tasks' },
}

export type TaskTool =
  | { type: 'rename' }
  | { type: 'copy' }
  | { type: 'share' }
  | { type: 'regenerate' }
  | { type: 'addToWorkspace'; taskPublicId: string }

const TaskToolbar: React.FC<{
  task: AnyTask
  tools: Array<TaskTool>
  className?: string
}> = ({ task, tools, className }) => {
  const isDesktop = useIsDesktopScreen()

  const { isLoading, addToWorkspace } = useAddToWorkspaceTask()

  const handleSelect = (toolType: ToolType) => {
    const tool = tools.find((t) => t.type === toolType)!

    if (tool.type === 'rename') {
      GlobalEventService.main.emit('renameTask')
    } else if (tool.type === 'copy') {
      copyToClipboardAndNotify(taskHelpers.transformTaskToMarkdown(task))
    } else if (tool?.type === 'regenerate') {
      openRegenerateTaskModal(task.id)
    } else if (tool.type === 'share') {
      openTaskSharingModal(task.id)
    } else if (tool.type === 'addToWorkspace') {
      addToWorkspace(tool.taskPublicId)
    }
  }

  if (!isDesktop) {
    return (
      <DropdownMenuPrimitive.Root modal={false}>
        <DropdownMenuPrimitive.Trigger asChild>
          <button
            type="button"
            className="flex h-14 w-14 shrink-0 flex-row items-center justify-center rounded-3xl border border-contrast bg-transparent hover:text-primary"
            aria-label="Open task toolbar"
          >
            <IconMenuDots />
          </button>
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Content
          className="min-w-[280px] rounded-3xl bg-level1 px-0 pb-3 pt-3 shadow-lg"
          side="bottom"
          align="end"
          sideOffset={24}
        >
          {tools.map((tool) => {
            const config = ToolsConfig[tool.type]
            return (
              <DropdownMenuPrimitive.Item
                key={tool.type}
                onSelect={() => handleSelect(tool.type)}
                className="flex w-full cursor-pointer items-center justify-start px-6 py-4 text-left hover:text-primary"
              >
                <span className="mr-4">{config.icon}</span>
                <span>{config.label}</span>
              </DropdownMenuPrimitive.Item>
            )
          })}
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    )
  }

  return (
    <div className={cx('flex flex-row items-center rounded-3xl', className)}>
      {tools.some((task) => task.type === 'addToWorkspace') ? (
        <Button
          variant="outlined"
          className="mr-4 !max-h-[48px] !min-h-[48px] w-[190px]"
          onClick={() => handleSelect('addToWorkspace')}
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          Add to my tasks
          <IconPlusSm className="ml-2" />
        </Button>
      ) : null}

      {tools.map((tool) => {
        if (tool.type === 'addToWorkspace') {
          return null
        }

        const config = ToolsConfig[tool.type]
        return (
          <button
            key={tool.type}
            type="button"
            className="inline-flex h-12 w-12 flex-row items-center justify-center transition-all hover:text-primary active:scale-95"
            onClick={() => handleSelect(tool.type)}
            aria-label={config.label}
          >
            {config.icon}
          </button>
        )
      })}
    </div>
  )
}

export default TaskToolbar
