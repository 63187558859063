import { useQuery } from '@tanstack/react-query'

import { icpApi } from '@/modules/icp/api'

const useIcp = (icpId: string) => {
  return useQuery({
    queryKey: ['icp', icpId],
    queryFn: () => {
      return icpApi.getIcp(icpId)
    },
  })
}

export default useIcp
