import { notify } from '@onsaui'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { icpActions } from '@/modules/icp'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

const useIcpActions = () => {
  const [isDeleting, setIsDeleting] = useState(false)

  const { workspaceId, icpId: paramIcpId } = useParams()
  const navigate = useNavigate()

  const deleteIcp = async (icpId: string, onDeleted?: () => void) => {
    setIsDeleting(true)

    let isDeleted = false
    try {
      await icpActions.deleteIcp(icpId)

      if (paramIcpId === icpId) {
        if (workspaceId) {
          navigate(`/${workspaceId}`)
        } else {
          navigate('/')
        }
      }

      isDeleted = true
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsDeleting(false)
    }

    if (isDeleted) {
      onDeleted?.()
    }
  }

  return { deleteIcp, isDeleting }
}

export default useIcpActions
