import { baseTaskAdapter } from './baseTaskAdapter'
import { GenericTask, GenericTaskResponse } from './taskGenericInterfaces'
import { TaskType } from './taskTypeInterfaces'

export interface TaskConfig<
  TType extends TaskType,
  GDataResponse,
  GData,
  GTaskResponse extends GenericTaskResponse<TType, GDataResponse>,
  GTask extends GenericTask<TType, GData>,
> {
  type: TType

  taskDataAdapter: (data: GDataResponse) => GData
  taskAdapter: (taskResponse: GTaskResponse) => GTask
}

export abstract class TaskBaseConfig<
  TType extends TaskType,
  GDataResponse,
  GData,
  GTaskResponse extends GenericTaskResponse<TType, GDataResponse>,
  GTask extends GenericTask<TType, GData>,
> implements TaskConfig<TType, GDataResponse, GData, GTaskResponse, GTask>
{
  type: TType

  constructor(type: TType) {
    this.type = type
  }

  abstract taskDataAdapter: (data: GDataResponse) => GData

  taskAdapter = (taskResponse: GTaskResponse) => {
    const baseTask = baseTaskAdapter(taskResponse)

    if (taskResponse.status !== 'completed') {
      const task: GTask = {
        ...baseTask,

        responseData: taskResponse.responseData,
      } as GTask

      return task
    }

    const task: GTask = {
      ...baseTask,

      responseData: this.taskDataAdapter(taskResponse.responseData),
    } as GTask

    return task
  }
}
