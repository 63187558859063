import { Button, Input, Modal, notify } from '@onsaui'
import { IllustrationWelcome } from '@onsaui/icons'
import { useEffect, useState } from 'react'

import { closeContactSalesModal, useBillingStore } from '@/modules/billing/store/billingStore'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { userApi } from '@/modules/user/api'
import AnalyticsService from '@/services/analytics'

const ContactSalesModal: React.FC = () => {
  const { isContactSalesModalOpen } = useBillingStore()

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }
  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value)
  }

  useEffect(() => {
    if (isContactSalesModalOpen) {
      setEmail('')
      setName('')
      setCompany('')
    }
  }, [isContactSalesModalOpen])

  const isValid = Boolean(email.trim()) && Boolean(name.trim()) && Boolean(company.trim())

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    AnalyticsService.trackEvent('ContactSalesModal.BtnSubmit.Click')

    setIsSubmitting(true)
    try {
      await userApi.sendCustomPlanRequest(name, email, company)

      AnalyticsService.trackEvent('ContactSalesModal.RequestSent', { name, email, company })

      closeContactSalesModal()

      notify({
        message: 'Thank you for sharing your details 🙌\nOur team will contact you shortly.',
        variant: 'success',
        duration: 5000,
      })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (isContactSalesModalOpen) {
      AnalyticsService.trackEvent('ContactSalesModal.Open')
    }
  }, [isContactSalesModalOpen])

  return (
    <Modal className="max-w-sm" isOpen={isContactSalesModalOpen} onClose={closeContactSalesModal}>
      <IllustrationWelcome className="m-auto mb-5" />
      <h2 className="mb-5 text-center">Connect with Our Sales Team</h2>
      <p className="mb-5 text-center">
        Please share your details below and our team will get in touch with you shortly.
      </p>

      <form onSubmit={handleSubmit}>
        <Input
          id="email"
          placeholder="Email"
          className="mb-5"
          type="email"
          value={email}
          onChange={handleEmailChange}
        />
        <Input
          id="name"
          placeholder="Name"
          className="mb-5"
          value={name}
          onChange={handleNameChange}
        />
        <Input
          id="company"
          placeholder="Tell us about your company"
          className="mb-5"
          value={company}
          onChange={handleCompanyChange}
        />

        <Button
          type="submit"
          className="mb-5 w-full"
          isDisabled={!isValid || isSubmitting}
          isLoading={isSubmitting}
        >
          Send
        </Button>
      </form>
    </Modal>
  )
}
export default ContactSalesModal
