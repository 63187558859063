import imgAva from '@assets/avatar_placeholder.png'
import React from 'react'

import SalesBot from '@/modules/salesBot/components/SalesBot/SalesBot'

const SalesBotPage: React.FC = () => {
  return (
    <main className="flex h-full w-full flex-col items-center overflow-auto bg-[#EFF2F6]">
      <header className="flex w-full flex-row items-center px-6 py-10">
        <p className="text-3xl">Sales Pilot</p>

        <div className="flex-grow" />

        <img src={imgAva} className="h-16 w-16 overflow-hidden rounded-full" alt="Avatar" />
      </header>
      <SalesBot />
    </main>
  )
}

export default SalesBotPage
