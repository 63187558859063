/* eslint-disable class-methods-use-this */

import { TaskBaseConfig } from '../../taskConfig'
import { TaskType } from '../../taskTypeInterfaces'
import { PersonMemoTaskDataResponse, TaskResponse } from './personMemoApiInterfaces'
import { PersonMemoTaskData, Task } from './personMemoInterfaces'

class PersonMemoTaskConfig extends TaskBaseConfig<
  TaskType.PersonMemo,
  PersonMemoTaskDataResponse,
  PersonMemoTaskData,
  TaskResponse,
  Task
> {
  constructor() {
    super(TaskType.PersonMemo)
  }

  taskDataAdapter = (responseData: PersonMemoTaskDataResponse) => {
    const { response } = responseData

    const companySummary = response.company_summary
    const discussionTopics = response.discussion_topics

    const data: PersonMemoTaskData = {
      personSummary: {
        ...response.person_summary,
        linkedin_avatar_url: response.person_summary.linkedin_avatar_url ?? undefined,
      },
      companySummary: companySummary
        ? {
            ...companySummary,
            name: companySummary.name ?? undefined,
          }
        : undefined,
      discussionTopics: discussionTopics
        ? {
            topics: discussionTopics.topics.map((topic) => ({
              ...topic,
              explanation: topic.explanation ?? undefined,
            })),
            starters: discussionTopics.starters ?? undefined,
          }
        : undefined,
    }

    return data
  }
}

export const taskConfig = new PersonMemoTaskConfig()
