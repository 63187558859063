import { ReactComponent as IllustrationDocument } from '@assets/illustration_document.svg'
import React from 'react'

import Button from '../Button/Button'

const defaultUserDetails =
  "I'm a middle-sales manager in a tech company, I like information to be presented in a clear and concise manner."

const MemoWelcome: React.FC<{
  onGenerate: (userDetails: string, callDetails?: string) => void
}> = ({ onGenerate }) => {
  const [userDetails, setUserDetails] = React.useState<string>(defaultUserDetails)
  const [callDetails, setCallDetails] = React.useState<string>('')

  const handleGenerate = () => {
    onGenerate(userDetails, callDetails)
  }

  return (
    <section className="flex w-[800px] flex-col rounded-3xl bg-white px-6 pb-6 pt-12">
      <IllustrationDocument className="mb-5 self-center" />
      <h3 className="mb-8 text-center text-2xl">Get a Call Memo</h3>

      <h4 className="font-medium">User details</h4>
      <textarea
        className="w-full resize-none rounded-2xl border border-gray-300 px-8 py-5"
        rows={6}
        placeholder="Some input..."
        value={userDetails}
        onChange={(e) => setUserDetails(e.target.value)}
      />

      <h4 className="mt-2 font-medium">Call details</h4>
      <textarea
        className="w-full resize-none rounded-2xl border border-gray-300 px-8 py-5"
        rows={6}
        placeholder="Some input..."
        value={callDetails}
        onChange={(e) => setCallDetails(e.target.value)}
      />

      <Button className="w-full" onClick={handleGenerate}>
        Generate
      </Button>
    </section>
  )
}

export default MemoWelcome
