import { icpApi } from '@/modules/icp/api'
import { Icp } from '@/modules/icp/model'
import { QueryService } from '@/services/query'

export const invalidateIcp = (id: string) => {
  QueryService.getClient().invalidateQueries({ queryKey: ['icp', id] })
}

export const invalidateIcps = () => {
  QueryService.getClient().invalidateQueries({ queryKey: ['icps'] })
}

export const setIcp = (icp: Icp) => {
  QueryService.getClient().setQueryData(['icp', icp.id], icp)
}

export const updateIcp = async (id: string, changes: Partial<Icp>) => {
  const icp = await icpApi.updateIcp(id, changes)
  setIcp(icp)

  return icp
}

export const regenerateIcp = async (id: string) => {
  const icp = await icpApi.regenerateIcp(id)
  setIcp(icp)

  return icp
}

export const deleteIcp = async (id: string) => {
  await icpApi.deleteIcp(id)

  QueryService.getClient().removeQueries({ queryKey: ['icp', id] })
  QueryService.getClient().setQueriesData({ queryKey: ['icps'] }, (oldData?: Icp[]) => {
    if (!oldData) {
      return oldData
    }

    return oldData.filter((icp) => icp.id !== id)
  })
}
