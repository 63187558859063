import { CircleIconPerson, IconTrash } from '@onsaui/icons'
import cx from 'clsx'
import { Link, useParams } from 'react-router-dom'

import useIcpActions from '@/modules/icp/hooks/useIcpActions'
import useIcps from '@/modules/icp/hooks/useIcps'
import { IcpMinimal } from '@/modules/icp/model'

const SidebarIcpList: React.FC<{ selectedIcpId?: string; className: string }> = ({
  selectedIcpId,
  className,
}) => {
  const { workspaceId } = useParams()
  const { data: icps, isPending, isError } = useIcps()

  const { deleteIcp } = useIcpActions()
  const handleDeleteIcp = (icp: IcpMinimal) => {
    deleteIcp(icp.id)
  }

  if (isPending) {
    return null
  }

  if (isError) {
    return <p className="text-danger">Error...</p>
  }

  return (
    <ul className={cx('flex flex-col gap-2', className)}>
      {icps.map((icp) => {
        // const isError = icp.status === TaskStatus.Failed

        const icon = <CircleIconPerson className="h-10 w-10 shrink-0 text-primary/70" />

        return (
          <li key={icp.id} className="group relative">
            <Link
              to={`/${workspaceId}/icp/${icp.id}`}
              className={cx(
                'sidebar-int flex h-16 w-full flex-row items-center gap-2 rounded-2xl border border-disabled p-2 pr-8 text-left',
                {
                  'border-primary bg-primary-surface': icp.id === selectedIcpId,
                },
              )}
            >
              {icon}
              <div className="flex flex-col justify-center overflow-hidden">
                <p className="line-clamp-2">{icp.title}</p>
              </div>
            </Link>
            <button
              type="button"
              onClick={() => handleDeleteIcp(icp)}
              className={cx(
                'absolute right-0 top-0 flex h-full flex-col items-center justify-center px-2 text-secondary opacity-0 transition-all hover:text-danger group-hover:flex group-hover:opacity-100',
              )}
              aria-label="Delete task"
            >
              <IconTrash />
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export default SidebarIcpList
