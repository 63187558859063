import { notify } from '@onsaui'
import copyToClipboard from 'shared/helpers/copyToClipboard'

const copyToClipboardAndNotify = async (text: string) => {
  if (!text) {
    notify({ message: 'Nothing to copy', variant: 'error' })

    return
  }

  try {
    await copyToClipboard(text)
    notify({ message: 'Copied', variant: 'success' })
  } catch {
    notify({ message: 'Failed to copy', variant: 'error' })
  }
}

export default copyToClipboardAndNotify
