import { notify } from '@onsaui'
import { useState } from 'react'

import { billingApi } from '@/modules/billing/api'
import { BillingPlan, BillingPlanTier } from '@/modules/billing/model'
import { openContactSalesModal } from '@/modules/billing/store/billingStore'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

const useUpgradeToPlan = () => {
  const [processingPlanId, setProcessingPlanId] = useState<string | null>(null)

  const upgradeToPlan = async (plan: BillingPlan, isAnnual: boolean, seats: number) => {
    if (plan.tier === BillingPlanTier.CUSTOM) {
      openContactSalesModal()
      return
    }

    setProcessingPlanId(plan.id)
    try {
      const { url } = await billingApi.generatePaymentLink(plan.id, isAnnual, seats)
      window.open(url, '_self')
    } catch (error) {
      notify({ message: getErrorMessage(error), variant: 'error' })

      throw error
    } finally {
      setProcessingPlanId(null)
    }
  }

  return { processingPlanId, upgradeToPlan }
}
export default useUpgradeToPlan
