import React from 'react'

import HtmlContainer from '@/modules/htmlContainer/components/HtmlContainer/HtmlContainer'
import { HtmlContainerData } from '@/modules/htmlContainer/model'
import { salesBotApi } from '@/modules/salesBot/api'
import { Loader } from '@/onsaui'

import Button from '../Button/Button'
import MemoWelcome from './MemoWelcome'

const SalesBot: React.FC = () => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  const [data, setData] = React.useState<HtmlContainerData | null>(null)

  const handleGenerate = async (userDetails: string, callDetails?: string) => {
    setIsProcessing(true)
    try {
      const data = await salesBotApi.generateMemo(userDetails, callDetails)
      setData(data)
    } catch (e) {
      setIsError(true)
      console.error(e)
    } finally {
      setIsProcessing(false)
    }
  }

  const handleReset = () => {
    setData(null)
    setIsError(false)
  }

  if (isError) {
    return (
      <div className="flex h-[480px] w-[360px] flex-col items-center justify-center rounded-3xl bg-white p-12">
        <p className="text-red-400">Something went wrong ...</p>
        <Button onClick={handleReset}>Retry</Button>
      </div>
    )
  }

  if (data) {
    return (
      <section className="flex w-[1024px] flex-col rounded-3xl bg-white p-6">
        <div className="mb-8 flex flex-row items-center justify-between">
          <h3 className="text-2xl">Result</h3>
          <Button onClick={handleReset}>Try Again</Button>
        </div>

        <HtmlContainer data={data} />
      </section>
    )
  }

  if (isProcessing) {
    return (
      <div className="flex h-[480px] w-[360px] flex-col items-center justify-center rounded-3xl bg-white p-12">
        <Loader />
      </div>
    )
  }

  return <MemoWelcome onGenerate={handleGenerate} />
}

export default SalesBot
