import { IconCopy, IconTrash } from '@onsaui/icons'
import cx from 'clsx'

import useOutreachTemplateActions from '@/modules/icp/hooks/useOutreachTemplateActions'
import { OutreachTemplate } from '@/modules/icp/model'
import copyToClipboardAndNotify from '@/modules/shared/helpers/copyToClipboardAndNotify'

const OutreachTemplateItem: React.FC<{
  icpId: string
  outreachTemplate: OutreachTemplate
  className?: string
}> = ({ icpId, outreachTemplate, className }) => {
  const { deleteOutreachTemplate, isDeleting } = useOutreachTemplateActions()

  const handleCopy = () => {
    copyToClipboardAndNotify(outreachTemplate.message)
  }

  const handleDelete = async () => {
    await deleteOutreachTemplate(icpId, outreachTemplate.id)
  }

  return (
    <div className={cx('flex flex-col rounded-3xl bg-[#D8E6FF] p-5', className)}>
      <div className="mb-4 flex flex-row items-center justify-between">
        <h2 className="font-semibold">{outreachTemplate.title}</h2>

        <div>
          <button
            type="button"
            className="p-2 transition-all hover:text-primary"
            onClick={handleCopy}
            aria-label="Copy"
          >
            <IconCopy />
          </button>
          <button
            type="button"
            className="p-2 transition-all hover:text-danger disabled:opacity-50"
            onClick={handleDelete}
            aria-label="Delete"
            disabled={isDeleting}
          >
            <IconTrash />
          </button>
        </div>
      </div>
      <p>{outreachTemplate.message}</p>
    </div>
  )
}

export default OutreachTemplateItem
