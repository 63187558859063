import { CircleIconError, CircleIconPerson, CircleIconPlay, IconTrash } from '@onsaui/icons'
import cx from 'clsx'
import { Link, useParams } from 'react-router-dom'

import useDeleteTask from '@/modules/task/hooks/useDeleteTask'
import useTasks from '@/modules/task/hooks/useTasks'
import { TaskInfo, TaskStatus, TaskType } from '@/modules/task/model'

const SidebarTaskList: React.FC<{ selectedTaskId?: string; className?: string }> = ({
  selectedTaskId,
  className,
}) => {
  const { workspaceId } = useParams()
  const { data: tasks, isPending, isError } = useTasks()

  const { deleteTask } = useDeleteTask()
  const handleDeleteTask = (task: TaskInfo) => {
    deleteTask(task.id)
  }

  if (isPending) {
    return null
  }

  if (isError) {
    return <p className="text-danger">Error...</p>
  }

  const allowedTasks = [TaskType.TranscriptReport, TaskType.PersonMemo]
  const tasksToShow = tasks.filter((task) => allowedTasks.includes(task.type))

  return (
    <ul className={cx('flex flex-col gap-2', className)}>
      {tasksToShow.map((task) => {
        const isError = task.status === TaskStatus.Failed

        let icon = null
        if (isError) {
          icon = <CircleIconError className="h-10 w-10 shrink-0" />
        } else if (task.type === TaskType.TranscriptReport) {
          icon = <CircleIconPlay className="h-10 w-10 shrink-0 text-primary/70" />
        } else if (task.type === TaskType.PersonMemo) {
          icon = <CircleIconPerson className="h-10 w-10 shrink-0 text-primary/70" />
        }

        return (
          <li key={task.id} className="group relative">
            <Link
              to={`/${workspaceId}/t/${task.id}`}
              className={cx(
                'sidebar-int flex h-16 w-full flex-row items-center gap-2 rounded-2xl border border-disabled p-2 pr-8 text-left',
                {
                  'border-primary bg-primary-surface': task.id === selectedTaskId && !isError,
                  'border-danger': task.id === selectedTaskId && isError,
                  'bg-danger/10': task.status === TaskStatus.Failed,
                },
              )}
            >
              {icon}
              <div className="flex flex-col justify-center overflow-hidden">
                <p className="line-clamp-2">
                  {task.status === TaskStatus.Failed ? 'Failed Task' : task.title}
                </p>
              </div>
            </Link>
            <button
              type="button"
              onClick={() => handleDeleteTask(task)}
              className={cx(
                'absolute right-0 top-0 flex h-full flex-col items-center justify-center px-2 text-secondary opacity-0 transition-all hover:text-danger group-hover:flex group-hover:opacity-100',
              )}
              aria-label="Delete task"
            >
              <IconTrash />
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export default SidebarTaskList
