import {
  PersonMemoTaskData,
  PersonMemoTaskDataExtended,
  PersonSummary,
} from './personMemoInterfaces'

export const transformPersonMemoToMarkdown = (data: PersonMemoTaskData): string => {
  let markdown = `# ${data.personSummary.name}\n\n`

  if (data.personSummary.linkedin_url) {
    markdown += `### [LinkedIn Profile](${data.personSummary.linkedin_url})\n\n`
  }

  if (data.personSummary.linkedin_avatar_url) {
    markdown += `<img src="${data.personSummary.linkedin_avatar_url}" alt="${data.personSummary.name}" width="200"/>\n\n`
  }

  if (data.personSummary.sections) {
    data.personSummary.sections.forEach((section) => {
      markdown += `## ${section.title}\n\n`
      section.items.forEach((item) => {
        markdown += `- ${item}\n`
      })
      markdown += '\n'
    })
  }

  if (data.companySummary) {
    if (data.companySummary.name) {
      markdown += `# Company: ${data.companySummary.name}\n\n`
    }
    data.companySummary.sections.forEach((section) => {
      markdown += `## ${section.title}\n\n`
      section.items.forEach((item) => {
        markdown += `### ${item.title}\n\n`
        markdown += `${item.content}\n\n`
      })
    })
  }

  if (data.discussionTopics) {
    if (data.discussionTopics.starters && data.discussionTopics.starters.length > 0) {
      markdown += `## Conversation Starters\n\n`
      data.discussionTopics.starters.forEach((starter) => {
        markdown += `### ${starter.question}\n\n`
        markdown += `- ${starter.explanation}\n\n`
      })
    }

    markdown += `## Discussion Topics\n\n`
    data.discussionTopics.topics.forEach((topic) => {
      markdown += `- [**${topic.post_title}**](${topic.post_url})\n`
      if (topic.explanation) {
        markdown += `  - **Explanation**: ${topic.explanation}\n`
      }
      markdown += `  - **Question:** ${topic.conversation_starter}\n`
      markdown += '\n'
    })
  }

  return markdown
}

export const extendPersonMemo = (data: PersonMemoTaskData) => {
  let currentRole: string | undefined
  let descriptionItems: string[] | undefined

  let educationItems: string[] | undefined
  let experienceItems: string[] | undefined

  let accomplishmentsItems: string[] | undefined

  const personSummary: PersonSummary = { ...data.personSummary, sections: [] }

  data.personSummary.sections.forEach((section) => {
    if (section.title.toLowerCase() === 'Current role'.toLowerCase()) {
      currentRole = section.items?.[0]
    } else if (section.title.toLowerCase() === 'Description'.toLowerCase()) {
      descriptionItems = section.items
    } else if (section.title.toLowerCase() === 'Education'.toLowerCase()) {
      educationItems = section.items
    } else if (section.title.toLowerCase() === 'Experience'.toLowerCase()) {
      experienceItems = section.items
    } else if (section.title.toLowerCase() === 'Notable accomplishments'.toLowerCase()) {
      accomplishmentsItems = section.items
    } else {
      personSummary.sections.push(section)
    }
  })

  const extendedData: PersonMemoTaskDataExtended = {
    personSummary,
    companySummary: data.companySummary,
    discussionTopics: data.discussionTopics,

    currentRole,
    descriptionItems,
    educationItems,
    experienceItems,
    accomplishmentsItems,
  }
  return extendedData
}
