import { Input, notify } from '@onsaui'
import cx from 'clsx'
import { useCallback, useEffect, useState } from 'react'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { taskActions } from '@/modules/task/actions'
import { AnyTask, taskHelpers } from '@/modules/task/model'
import GlobalEventService from '@/services/globalEvent'

const TitleEditor: React.FC<{ task: AnyTask; isNoEdit?: boolean; className?: string }> = ({
  task,
  isNoEdit,
  className,
}) => {
  const [title, setTitle] = useState('')
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const [isEditing, setIsEditing] = useState(false)
  const startEditing = useCallback(() => {
    setTitle(taskHelpers.getTaskTitle(task))
    setIsEditing(true)
  }, [task])

  useEffect(() => {
    GlobalEventService.main.on('renameTask', startEditing)
    return () => {
      GlobalEventService.main.off('renameTask', startEditing)
    }
  }, [startEditing])

  const [isSaving, setIsSaving] = useState(false)

  const handleSave = async () => {
    if (task.title === title.trim()) {
      setIsEditing(false)
      return
    }

    setIsSaving(true)
    try {
      await taskActions.renameTask(task.id, title)

      notify({ message: 'Saved', variant: 'success' })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })

      throw e
    } finally {
      setIsSaving(false)
      setIsEditing(false)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave()
    }
  }

  if (isNoEdit) {
    return (
      <h1 className={cx('inline-flex mobile:text-h2', className)}>
        {taskHelpers.getTaskTitle(task)}
      </h1>
    )
  }

  if (isEditing) {
    return (
      <Input
        className={cx('!inline-flex w-full', className)}
        value={title}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleSave}
        isDisabled={isSaving}
        autoFocus
      />
    )
  }

  return (
    <button
      type="button"
      className={cx('inline-flex cursor-text', className)}
      onClick={startEditing}
    >
      <h1 className="mobile:text-h2">{taskHelpers.getTaskTitle(task)}</h1>
    </button>
  )
}

export default TitleEditor
