import React from "react";
import clsx from "clsx";

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (
  props
) => {
  const { className, ...rest } = props;

  return (
    <button
      className={clsx(
        "bg-black px-8 h-12 rounded-2xl text-white mt-4 hover:bg-black/90 active:scale-[0.98] transition-all",
        className
      )}
      {...rest}
    />
  );
};

export default Button;
