import { TextArea } from '@onsaui'
import { IconChevronDownMini } from '@onsaui/icons'
import cx from 'clsx'
import { useEffect, useId, useRef, useState } from 'react'

const CreateTaskContextField: React.FC<
  { className?: string } & React.HTMLProps<HTMLTextAreaElement>
> = ({ className, ...props }) => {
  const ref = useRef<HTMLTextAreaElement>(null)
  const id = useId()

  const [isFolded, setIsFolded] = useState(true)
  const toggleFold = () => {
    setIsFolded((prev) => !prev)
  }

  useEffect(() => {
    if (!isFolded) {
      setTimeout(() => {
        ref.current?.focus()
      }, 10)
    }
  }, [isFolded])

  return (
    <div className={cx('flex flex-col', className)}>
      <button
        type="button"
        className="flex w-full flex-row items-center justify-between"
        onClick={toggleFold}
      >
        <label htmlFor={id} className="mb-2">
          Add context
        </label>
        <IconChevronDownMini className={cx('transition-all', { 'rotate-180': !isFolded })} />
      </button>
      <div
        className={cx('overflow-hidden transition-all', {
          'invisible max-h-0 opacity-0': isFolded,
          'max-h-[96px] opacity-100': !isFolded,
        })}
      >
        <TextArea
          {...props}
          ref={ref}
          id={id}
          placeholder="Any additional info for more accurate result"
        />
      </div>
    </div>
  )
}

export default CreateTaskContextField
