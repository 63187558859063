import { notify } from '@onsaui'
import {
  CircleIconPrivate,
  CircleIconPublic,
  CircleIconRestricted,
  IconChevronDownMini,
} from '@onsaui/icons'
import { Content, Item, Root, Trigger } from '@radix-ui/react-dropdown-menu'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { taskActions } from '@/modules/task/actions'
import { TaskAccess, TaskAccessType } from '@/modules/task/model'

const options = {
  [TaskAccessType.Private]: {
    icon: <CircleIconPrivate className="shrink-0" />,
    label: 'Private',
    description: 'Only you can view',
  },
  [TaskAccessType.Invite]: {
    icon: <CircleIconRestricted className="shrink-0" />,
    label: 'Invites Only',
    description: 'Only people with access can view',
  },
  [TaskAccessType.Public]: {
    icon: <CircleIconPublic className="shrink-0" />,
    label: 'Public',
    description: 'Anyone on the internet with the link can view',
  },
}

const AccessDropdown: React.FC<{ taskId: string; access: TaskAccess }> = ({ taskId, access }) => {
  const handleSelect = async (type: TaskAccessType) => {
    if (access.accessType === type) {
      return
    }

    try {
      await taskActions.updateTaskAccess(taskId, { ...access, accessType: type })

      notify({ message: 'Updated', variant: 'success' })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
    }
  }

  const option = options[access.accessType]

  return (
    <Root>
      <Trigger asChild>
        <button
          type="button"
          className="group flex h-12 flex-row items-center gap-2 text-start"
          aria-label="Open task toolbar"
        >
          {option.icon}
          <div>
            <div className="flex flex-row items-center gap-2">
              <p>{option.label}</p>
              <IconChevronDownMini className="transition-transform group-data-[state=open]:rotate-180" />
            </div>

            <p className="text-sm text-secondary">{option.description}</p>
          </div>
        </button>
      </Trigger>
      <Content
        className="w-[300px] rounded-3xl border bg-level1 py-4 shadow-2xl"
        align="start"
        sideOffset={12}
      >
        {Object.values(TaskAccessType).map((type) => {
          if (type === access.accessType) {
            return null
          }

          const option = options[type]

          return (
            <Item
              key={type}
              className="cursor-pointer px-4 py-2 hover:text-primary"
              onSelect={() => handleSelect(type)}
            >
              <div className="flex flex-row items-center gap-2">
                {option.icon}
                <div>
                  <p>{option.label}</p>
                  <p className="text-sm text-secondary">{option.description}</p>
                </div>
              </div>
            </Item>
          )
        })}
      </Content>
    </Root>
  )
}

export default AccessDropdown
