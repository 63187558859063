import { HtmlContainerData } from "@/modules/htmlContainer/model";
import ErrorBoundary from "@/modules/shared/components/ErrorBoundary/ErrorBoundary";

const HtmlContainer: React.FC<{ data: HtmlContainerData }> = ({ data }) => {
  return (
    <ErrorBoundary
      fallback={
        <div>
          <p className="text-red-400">Unable to render result...</p>
        </div>
      }
    >
      <style>{data.css}</style>
      <div
        className="html-container"
        dangerouslySetInnerHTML={{
          __html: data.html,
        }}
      />
    </ErrorBoundary>
  );
};

export default HtmlContainer;
