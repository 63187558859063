import { create } from 'zustand'

export interface TaskStore {
  taskRegenerateModal: {
    isOpen: boolean
    taskId: string
  }

  taskSharingModal: {
    isOpen: boolean
    taskId: string
  }
}

export const useTaskStore = create<TaskStore>((set) => ({
  taskRegenerateModal: {
    isOpen: false,
    taskId: '',
  },

  taskSharingModal: {
    isOpen: false,
    taskId: '',
  },
}))

export const openRegenerateTaskModal = (taskId: string) => {
  useTaskStore.setState({ taskRegenerateModal: { isOpen: true, taskId } })
}
export const closeRegenerateTaskModal = () => {
  useTaskStore.setState({ taskRegenerateModal: { isOpen: false, taskId: '' } })
}

export const openTaskSharingModal = (taskId: string) => {
  useTaskStore.setState({ taskSharingModal: { isOpen: true, taskId } })
}
export const closeTaskSharingModal = () => {
  useTaskStore.setState({ taskSharingModal: { isOpen: false, taskId: '' } })
}

// @ts-expect-error - expose to the window
window.openRegenerateTaskModal = openRegenerateTaskModal

// @ts-expect-error - expose to the window
window.openTaskSharingModal = openTaskSharingModal
